import { COLORS } from '@apari-shared/constants';
import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ApariOfflineStatus' })(() => ({
    wrapper: {
        zIndex: 9999,
        position: 'fixed',
        top: 0,
        width: '100%'
    },
    container: {
        background: COLORS.red,
        textAlign: 'center',
        '& span': {
            fontSize: 10,
            color: COLORS.apariBlack60
        }
    }
}));
