const RESPONSE_CODES: Record<string, any> = {
    NOT_AUTHORIZED: 401,
    USER_ALREADY_EXISTS: 500,
    BAD_CREDENTIALS: 405,
    USER_DOES_NOT_EXISTS: 409,
    EXPIRED_TOKEN: 410,
    INVALID_TOKEN: 498,
    USER_NOT_CONFIRMED: 407,
    NOT_IMPLEMENTED: 451,
    CARD_ERROR: 600,
    STRIPE_ERROR: 601,
    TWO_FACTOR_AUTHENTICATION_REQUIRED: 706,
    ACCOUNT_ALREADY_CONFIRMED: 705,
    OAUTH_USER_ALREADY_EXITS: 702,
    INVALID_TWO_FACTOR_CODE: 708,
    RECOVERY_CODE_USED_ON_ACTIVATION: 709,
    ALREADY_REGISTERED_USING_EMAIL: 716,
    CANT_RESET_PASSWORD_FOR_THIS_EMAIL: 715,
    HMRC_AUTHORIZATION_REQUIRED: 717,
    DELETE_ALREADY_REQUESTED: 721,
    741: 'THIS_ACCOUNT_IS_MANAGED_BY_AGENT'
};

export default RESPONSE_CODES;
