import { isSamsungBrowser, browserVersion } from 'react-device-detect';
import { makeStyles } from 'tss-react/mui';
import { FONTS, GLOBAL } from 'constants/index';
import { COLORS } from '@apari-shared/constants';

export default makeStyles({ name: 'GlobalStyles' })(theme => ({
    positionRelative: {
        position: 'relative'
    },
    topMargin: {
        marginTop: GLOBAL.TOP_MARGIN
    },
    fullHeight: {
        height: '100%'
    },
    widthFitContent: {
        width: 'fit-content'
    },
    fullFlex: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    autoHeight: {
        height: 'auto'
    },
    autoFullHeight: {
        height: 'auto',
        minHeight: '100%'
    },
    fullWidth: {
        width: '100%'
    },
    halfWidth: {
        width: '50%'
    },
    width25: {
        width: '25%'
    },
    width30: {
        width: '30%'
    },
    width40: {
        width: '40%'
    },
    width60: {
        width: '60%'
    },
    width70: {
        width: '70%'
    },
    width75: {
        width: '75%'
    },
    flexRow: {
        display: 'flex',
        flexDirection: 'row'
    },
    flexRowCenter: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    flexRowStart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    flexRowEnd: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center'
    },
    flexRowSpaceBetween: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexRowSpaceEvenly: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column'
    },
    flexColumnCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    },
    flexColumnVerticalCenter: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    flexColumnStart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start'
    },
    flexColumnEnd: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    flexColumnSpaceBetween: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    flexColumnAlignStart: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start'
    },
    flexWrap: {
        flexWrap: 'wrap'
    },
    alignSelfFlexStart: {
        alignSelf: 'flex-start'
    },
    alignSelfFlexEnd: {
        alignSelf: 'flex-end'
    },
    alignItemsFlexStart: {
        alignItems: 'flex-start'
    },
    alignItemsFlexEnd: {
        alignItems: 'flex-end'
    },
    alignSelfCenter: {
        alignSelf: 'center',
        justifySelf: 'center'
    },
    marginRightAuto: {
        marginRight: 'auto'
    },
    marginLeftAuto: {
        marginLeft: 'auto'
    },
    marginBottomAuto: {
        marginBottom: 'auto'
    },
    marginTopAuto: {
        marginTop: 'auto'
    },
    marginAuto: {
        margin: 'auto'
    },
    noMargin: {
        margin: '0px !important'
    },
    noPadding: {
        padding: '0px !important'
    },
    noPaddingBottom: {
        paddingBottom: '0px !important'
    },
    noVerticalMargin: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    },
    noHorizontalMargin: {
        marginLeft: '0px !important',
        marginRight: '0px !important'
    },
    noVerticalPadding: {
        paddingTop: '0px !important',
        paddingBottom: '0px !important'
    },
    noHorizontalPadding: {
        paddingLeft: '0px !important',
        paddingRight: '0px !important'
    },
    horizontalPadding16: {
        paddingLeft: 16,
        paddingRight: 16
    },
    verticalPadding16: {
        paddingTop: 16,
        paddingBottom: 16
    },
    verticalPadding12: {
        paddingTop: 12,
        paddingBottom: 12
    },
    verticalMargin8: {
        marginTop: 8,
        marginBottom: 8
    },
    verticalMargin4: {
        marginTop: 4,
        marginBottom: 4
    },
    horizontalPaddingMobile16: {
        [theme.breakpoints.down('lg')]: {
            paddingLeft: 16,
            paddingRight: 16
        }
    },
    horizontalPadding12: {
        paddingLeft: 12,
        paddingRight: 12
    },
    padding4: {
        padding: 4
    },
    padding8: {
        padding: 8
    },
    padding10: {
        padding: 10
    },
    padding12: {
        padding: 12
    },
    padding16: {
        padding: '16px !important'
    },
    padding20: {
        padding: 20
    },
    padding24: {
        padding: 24
    },
    padding28: {
        padding: 28
    },
    padding32: {
        padding: 32
    },
    padding50: {
        padding: 50
    },
    paddingTop0Forced: {
        paddingTop: '0px !important'
    },
    paddingTop2: {
        paddingTop: 2
    },
    paddingTop4: {
        paddingTop: 4
    },
    paddingTop8: {
        paddingTop: 8
    },
    paddingTop10: {
        paddingTop: 10
    },
    paddingTop12: {
        paddingTop: 12
    },
    paddingTop14: {
        paddingTop: 14
    },
    paddingTop16: {
        paddingTop: 16
    },
    paddingTop20: {
        paddingTop: 20
    },
    paddingTop24: {
        paddingTop: 24
    },
    paddingTop28: {
        paddingTop: 28
    },
    paddingTop32: {
        paddingTop: 32
    },
    paddingBottom4: {
        paddingBottom: 4
    },
    paddingBottom6: {
        paddingBottom: 6
    },
    paddingBottom8: {
        paddingBottom: 8
    },
    paddingBottom10: {
        paddingBottom: 10
    },
    paddingBottom16: {
        paddingBottom: '16px !important'
    },
    paddingBottom20: {
        paddingBottom: 20
    },
    paddingBottom24: {
        paddingBottom: 24
    },
    paddingBottom28: {
        paddingBottom: 28
    },
    paddingBottom32: {
        paddingBottom: 32
    },
    paddingLeft0: {
        paddingLeft: 0
    },
    paddingLeft4: {
        paddingLeft: 4
    },
    paddingLeft6: {
        paddingLeft: 6
    },
    paddingLeft8: {
        paddingLeft: 8
    },
    paddingLeft10: {
        paddingLeft: 10
    },
    paddingLeft16: {
        paddingLeft: 16
    },
    paddingLeft20: {
        paddingLeft: 20
    },
    paddingLeft24: {
        paddingLeft: 24
    },
    paddingLeft28: {
        paddingLeft: 28
    },
    paddingLeft32: {
        paddingLeft: 32
    },
    paddingLeft34: {
        paddingLeft: 34
    },
    paddingLeft36: {
        paddingLeft: 36
    },
    paddingLeft50: {
        paddingLeft: 50
    },
    paddingRight4: {
        paddingRight: 4
    },
    paddingRight6: {
        paddingRight: 6
    },
    paddingRight8: {
        paddingRight: 8
    },
    paddingRight10: {
        paddingRight: 10
    },
    paddingRight16: {
        paddingRight: 16
    },
    paddingRight20: {
        paddingRight: 20
    },
    paddingRight24: {
        paddingRight: 24
    },
    paddingRight28: {
        paddingRight: 28
    },
    paddingRight32: {
        paddingRight: 32
    },
    paddingRight50: {
        paddingRight: 50
    },
    paddingRight52: {
        paddingRight: 52
    },
    margin4: {
        margin: 4
    },
    margin8: {
        margin: 8
    },
    margin10: {
        margin: 10
    },
    margin16: {
        margin: 16
    },
    margin20: {
        margin: 20
    },
    margin24: {
        margin: 24
    },
    margin28: {
        margin: 28
    },
    margin32: {
        margin: 32
    },
    marginTop0: {
        marginTop: 0
    },
    marginTop2: {
        marginTop: 2
    },
    marginTop4: {
        marginTop: 4
    },
    marginTop8: {
        marginTop: 8
    },
    marginTop10: {
        marginTop: 10
    },
    marginTop12: {
        marginTop: 12
    },
    marginTop16: {
        marginTop: 16
    },
    marginTop20: {
        marginTop: 20
    },
    marginTop22: {
        marginTop: 22
    },
    marginTop24: {
        marginTop: 24
    },
    marginTop26: {
        marginTop: 26
    },
    marginTop28: {
        marginTop: 28
    },
    marginTop32: {
        marginTop: 32
    },
    marginTop40: {
        marginTop: 40
    },
    marginTop54: {
        marginTop: 54
    },
    marginTop88: {
        marginTop: 88
    },
    marginBottom0: {
        marginBottom: 0
    },
    marginBottom2: {
        marginBottom: 2
    },
    marginBottom4: {
        marginBottom: 4
    },
    marginBottom6: {
        marginBottom: 6
    },
    marginBottom8: {
        marginBottom: 8
    },
    marginBottom10: {
        marginBottom: 10
    },
    marginBottom12: {
        marginBottom: 12
    },
    marginBottom16: {
        marginBottom: 16
    },
    marginBottom20: {
        marginBottom: 20
    },
    marginBottom24: {
        marginBottom: 24
    },
    marginBottom26: {
        marginBottom: 26
    },
    marginBottom28: {
        marginBottom: 28
    },
    marginBottom30: {
        marginBottom: 30
    },
    marginBottom32: {
        marginBottom: 32
    },
    marginBottom36: {
        marginBottom: 36
    },
    marginBottom38: {
        marginBottom: 38
    },
    marginBottom40: {
        marginBottom: 40
    },
    marginBottom60: {
        marginBottom: 60
    },
    marginBottom80: {
        marginBottom: 80
    },
    marginLeft0: {
        marginLeft: 0
    },
    marginLeft4: {
        marginLeft: 4
    },
    marginLeft8: {
        marginLeft: 8
    },
    marginLeft10: {
        marginLeft: 10
    },
    marginLeft12: {
        marginLeft: 12
    },
    marginLeft14: {
        marginLeft: 14
    },
    marginLeft16: {
        marginLeft: 16
    },
    marginLeft18: {
        marginLeft: 18
    },
    marginLeft20: {
        marginLeft: 20
    },
    marginLeft24: {
        marginLeft: 24
    },
    marginLeft28: {
        marginLeft: 28
    },
    marginLeft32: {
        marginLeft: 32
    },
    marginLeft34: {
        marginLeft: 34
    },
    marginLeft48: {
        marginLeft: 48
    },
    marginRight4: {
        marginRight: 4
    },
    marginRight6: {
        marginRight: 6
    },
    marginRight8: {
        marginRight: 8
    },
    marginRight10: {
        marginRight: 10
    },
    marginRight12: {
        marginRight: 12
    },
    marginRight16: {
        marginRight: 16
    },
    marginRight20: {
        marginRight: 20
    },
    marginRight24: {
        marginRight: 24
    },
    marginRight28: {
        marginRight: 28
    },
    marginRight32: {
        marginRight: 32
    },
    marginRight36: {
        marginRight: 36
    },
    marginRight40: {
        marginRight: 40
    },
    marginRight44: {
        marginRight: 44
    },
    marginRight48: {
        marginRight: 48
    },
    marginRight52: {
        marginRight: 52
    },
    marginRight138: {
        marginRight: 138
    },
    borderRadius4: {
        borderRadius: 4
    },
    font10weight600White: {
        color: COLORS.apariWhite,
        fontSize: 10,
        fontWeight: 600
    },
    font10weight400: {
        color: theme.palette.primary.main,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight400Dark40: {
        color: COLORS.apariBlack40,
        fontSize: 10,
        fontWeight: 400
    },
    font16weight700dark: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        fontWeight: 700
    },
    font10weight400Purple: {
        color: COLORS.apariPurple,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight400Dark60: {
        color: COLORS.apariBlack60,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight500Dark60: {
        color: COLORS.apariBlack60,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 10,
        fontWeight: 600
    },
    font10weight600Dark60: {
        color: COLORS.apariBlack60,
        fontSize: 10,
        fontWeight: 600
    },
    font10weight400Dark100: {
        color: COLORS.apariBlack,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight600Dark100: {
        color: COLORS.apariBlack,
        fontSize: 10,
        fontWeight: 600
    },
    font10weight500: {
        color: theme.palette.primary.main,
        fontSize: 10,
        fontWeight: 500
    },
    font10weight600: {
        color: theme.palette.primary.main,
        fontSize: 10,
        fontWeight: 600
    },
    font10weight600Light40: {
        color: COLORS.apariBlack40,
        fontSize: 10,
        fontWeight: 600
    },
    font10weight700Black80: {
        color: COLORS.apariBlack80,
        fontSize: 10,
        fontWeight: 700
    },
    font10Weight700Black40: {
        color: COLORS.apariBlack40,
        fontSize: 10,
        fontWeight: 700
    },
    font12weight400: {
        fontSize: 12,
        fontWeight: 400
    },
    font12weight400Grey: {
        color: COLORS.apariGray2,
        fontSize: 12,
        fontWeight: 400
    },
    font12weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 12,
        fontWeight: 400
    },
    font12weight500Dark: {
        color: COLORS.apariBlack80,
        fontSize: 12,
        fontWeight: 500
    },
    font12weight500Purple: {
        color: COLORS.apariPurple,
        fontSize: 12,
        fontWeight: 500
    },
    font12weight500: {
        color: theme.palette.primary.main,
        fontSize: 12,
        fontWeight: 500
    },
    font11weight500Dark: {
        color: COLORS.apariBlack80,
        fontSize: 11,
        fontWeight: 500
    },
    font11weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 11,
        fontWeight: 600
    },
    font11weight500Black: {
        color: COLORS.apariBlack,
        fontSize: 11,
        fontWeight: 500
    },
    font12weight200Black: {
        color: COLORS.apariBlack,
        fontSize: 12,
        fontWeight: 200
    },
    font9weight400Black60: {
        color: COLORS.apariBlack60,
        fontSize: 9,
        fontWeight: 400
    },
    font9weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 9,
        fontWeight: 400
    },
    font11weight700purple: {
        color: COLORS.apariPurple,
        fontSize: 11,
        fontWeight: 700
    },
    font12weight600: {
        fontSize: 12,
        fontWeight: 600
    },
    font12weight600Primary: {
        color: theme.palette.primary.main,
        fontSize: 12,
        fontWeight: 600
    },
    font12weight500Black60: {
        color: COLORS.apariBlack60,
        fontSize: 12,
        fontWeight: 500
    },
    font12weight600Black60: {
        color: COLORS.apariBlack60,
        fontSize: 12,
        fontWeight: 600
    },
    font13weight400: {
        color: COLORS.apariBlack80,
        fontSize: 13,
        fontWeight: 400
    },
    font13weight600: {
        color: COLORS.apariBlack80,
        fontSize: 13,
        fontWeight: 600
    },
    font14weight500: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 500
    },
    font14weight400: {
        fontSize: 14,
        fontWeight: 400
    },
    font14weight600: {
        color: theme.palette.primary.main,
        fontSize: 14,
        fontWeight: 600
    },
    font14weight600Light: {
        color: COLORS.paperLight,
        fontSize: 14,
        fontWeight: 600
    },
    font14weight600Gray: {
        color: COLORS.gray4,
        fontSize: 14,
        fontWeight: 600
    },
    font16weight500: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 500
    },
    font18weight400: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 400
    },
    font18weight800Dark: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 800
    },
    font16weight600: {
        color: theme.palette.primary.main,
        fontSize: 16,
        fontWeight: 600
    },
    font16weight600White: {
        color: COLORS.apariWhite,
        fontSize: 16,
        fontWeight: 600
    },
    font16weight500dark: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        fontWeight: 500
    },
    font16weight600dark: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        fontWeight: 600
    },
    font16weight600Black: {
        color: COLORS.apariBlack,
        fontSize: 16,
        fontWeight: 600
    },
    font16weight600Gray: {
        color: COLORS.gray4,
        fontSize: 16,
        fontWeight: 600
    },
    font16weight800Dark: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        fontWeight: 800
    },
    font22weight500: {
        color: theme.palette.primary.main,
        fontSize: 22,
        fontWeight: 500
    },
    font22weight600: {
        color: theme.palette.primary.main,
        fontSize: 22,
        fontWeight: 600
    },
    font22weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 22,
        fontWeight: 400
    },
    font22weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 22,
        fontWeight: 600
    },
    font10weight400Light40: {
        color: COLORS.apariBlack40,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight400Light50: {
        color: COLORS.apariBlack50,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight400Light: {
        color: COLORS.apariBlack20,
        fontSize: 10,
        fontWeight: 400
    },
    font10weight400White: {
        color: COLORS.paperLight,
        fontSize: 10,
        fontWeight: 400
    },
    font12weight600Light: {
        color: COLORS.apariBlack20,
        fontSize: 12,
        fontWeight: 600
    },
    font12weight500Light: {
        color: COLORS.apariBlack20,
        fontSize: 12,
        fontWeight: 500
    },
    font12weight500Light40: {
        color: COLORS.apariBlack40,
        fontSize: 12,
        fontWeight: 500
    },
    font12weight400Light: {
        color: theme.palette.text.secondary,
        fontSize: 12,
        fontWeight: 400
    },
    font12weight400Dark60: {
        color: COLORS.apariBlack60,
        fontSize: 12,
        fontWeight: 400
    },
    font13weight600Light: {
        color: COLORS.apariBlack20,
        fontSize: 13,
        fontWeight: 600
    },
    font11weight400Light: {
        color: theme.palette.text.secondary,
        fontSize: 11,
        fontWeight: 400
    },
    font11weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 11,
        fontWeight: 400
    },
    font11weight700Dark: {
        color: COLORS.apariBlack80,
        fontSize: 11,
        fontWeight: 700
    },
    font13weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 13,
        fontWeight: 600
    },
    font13weight700Dark: {
        color: COLORS.apariBlack80,
        fontSize: 13,
        fontWeight: 700
    },
    font13weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 13,
        fontWeight: 400
    },
    font13weight400Purple: {
        color: COLORS.apariPurple,
        fontSize: 13,
        fontWeight: 400
    },
    font13weight400Black: {
        color: COLORS.apariBlack,
        fontSize: 13,
        fontWeight: 400
    },
    font13weight500Dark: {
        color: COLORS.apariBlack80,
        fontSize: 13,
        fontWeight: 500
    },
    font13weight400light40: {
        color: COLORS.apariBlack40,
        fontSize: 13,
        fontWeight: 400
    },
    font13weight400light60: {
        color: COLORS.apariBlack60,
        fontSize: 13,
        fontWeight: 400
    },
    font13weight400light: {
        color: theme.palette.text.secondary,
        fontSize: 13,
        fontWeight: 400
    },
    font14weight400light: {
        color: theme.palette.text.secondary,
        fontSize: 14,
        fontWeight: 400
    },
    font14weight500Light: {
        color: COLORS.paperLight,
        fontSize: 14,
        fontWeight: 500
    },
    font14weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 14,
        fontWeight: 600
    },
    font14weight700Dark: {
        color: COLORS.apariBlack80,
        fontSize: 14,
        fontWeight: 700
    },
    font14weight700Light: {
        color: COLORS.paperLight,
        fontSize: 14,
        fontWeight: 700
    },
    font14weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 14,
        fontWeight: 400
    },
    font14weight400Dark60: {
        color: COLORS.apariBlack60,
        fontSize: 14,
        fontWeight: 400
    },
    font16weight500Light: {
        color: COLORS.paperLight,
        fontSize: 16,
        fontWeight: 500
    },
    font16weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 16,
        fontWeight: 400
    },
    font16weight400Light: {
        color: COLORS.paperLight,
        fontSize: 16,
        fontWeight: 400
    },
    font18weight600Light: {
        color: COLORS.paperLight,
        fontSize: 18,
        fontWeight: 600
    },
    font18weight400Light: {
        color: COLORS.paperLight,
        fontSize: 18,
        fontWeight: 400
    },
    font26weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 26,
        fontWeight: 400
    },
    font28weight600Light: {
        color: COLORS.paperLight,
        fontSize: 28,
        fontWeight: 600
    },
    font10weight500Darker: {
        color: COLORS.apariBlack60,
        fontSize: 10,
        fontWeight: 500
    },
    font10weight500Black80: {
        color: COLORS.apariBlack80,
        fontSize: 10,
        fontWeight: 500
    },
    font12weight500Dark60: {
        color: COLORS.apariBlack60,
        fontSize: 12,
        fontWeight: 500
    },
    font12weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 12,
        fontWeight: 600
    },
    font12weight700Dark: {
        color: COLORS.apariBlack80,
        fontSize: 12,
        fontWeight: 700
    },
    font12weight700Light: {
        color: COLORS.paperLight,
        fontSize: 12,
        fontWeight: 700
    },
    font12weight600White: {
        color: COLORS.paperLight,
        fontSize: 12,
        fontWeight: 600
    },
    font13weight700White: {
        color: COLORS.paperLight,
        fontSize: 13,
        fontWeight: 700
    },
    font15weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 15,
        fontWeight: 600
    },
    font15weight700Purple: {
        color: COLORS.apariPurple,
        fontSize: 15,
        fontWeight: 700
    },
    font15weight600White: {
        color: COLORS.apariWhite,
        fontSize: 15,
        fontWeight: 600
    },
    font15weight600Grey: {
        color: COLORS.apariGray3,
        fontSize: 15,
        fontWeight: 600
    },
    font15weight500Dark: {
        color: COLORS.apariBlack80,
        fontSize: 15,
        fontWeight: 500
    },
    font15weight400Light40: {
        color: COLORS.apariBlack40,
        fontSize: 15,
        fontWeight: 400
    },
    font15weight700Dark80: {
        color: COLORS.apariBlack80,
        fontSize: 15,
        fontWeight: 700
    },
    font15weight700Dark: {
        color: COLORS.apariBlack,
        fontSize: 15,
        fontWeight: 700
    },
    font15weight700White: {
        color: COLORS.apariWhite,
        fontSize: 15,
        fontWeight: 700
    },
    font18weight400Dark: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 400
    },
    font18weight500Dark: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 500
    },
    font18weight600Dark: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 600
    },
    font18weight600: {
        color: theme.palette.primary.main,
        fontSize: 18,
        fontWeight: 600
    },
    font20weight600: {
        color: theme.palette.primary.main,
        fontSize: 20,
        fontWeight: 600
    },
    font20weight800Dark: {
        color: COLORS.apariBlack80,
        fontSize: 20,
        fontWeight: 800
    },
    font18weight900Dark: {
        color: COLORS.apariBlack80,
        fontSize: 18,
        fontWeight: 900
    },
    font12weight600HeaderMenuGray: {
        color: COLORS.headerMenuGray,
        fontSize: 12,
        fontWeight: 600
    },
    fontRoboto: {
        fontFamily: FONTS.QUATERNARY_FONT
    },
    opacity: {
        opacity: 0.5
    },
    textUppercase: {
        textTransform: 'uppercase'
    },
    textUnderline: {
        textDecoration: 'underline'
    },
    textLineThrough: {
        textDecoration: 'line-through'
    },
    textLowercase: {
        textTransform: 'lowercase'
    },
    textCapitalize: {
        textTransform: 'capitalize'
    },
    textNoTransformation: {
        textTransform: 'none'
    },
    overflowAuto: {
        overflow: 'auto'
    },
    overflowHidden: {
        overflow: 'hidden'
    },
    textAlignCenter: {
        textAlign: 'center'
    },
    preWrap: {
        whiteSpace: 'pre-wrap'
    },
    wrapText: {
        whiteSpace: 'break-spaces'
    },
    brakeAll: {
        wordBreak: 'break-all'
    },
    textAlignRight: {
        textAlign: 'right'
    },
    textAlignLeft: {
        textAlign: 'left'
    },
    cursorPointer: {
        cursor: 'pointer'
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingTop: 30,
        paddingBottom: 16,
        paddingLeft: 16,
        paddingRight: 16,
        flex: 'none',
        [theme.breakpoints.up('lg')]: {
            alignItems: 'flex-start',
            padding: 0
        },
        [theme.breakpoints.down('lg')]: {
            flex: isSamsungBrowser && Number(browserVersion) <= 11 ? 'none' : 1
        }
    },
    preventScrollbarFromMovingContent: {
        paddingLeft: 'calc(100vw - 100%); !important'
    },
    inputsFont: {
        '& span': {
            fontSize: '12px !important',
            fontWeight: 600
        }
    },
    CTAWrapper: {
        width: '100%',
        marginTop: 'auto',
        padding: 16
    },
    CTAWrapperTabletMode: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginTop: 'auto',
        padding: 16,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            maxWidth: 310,
            marginTop: 0,
            alignSelf: 'flex-start'
        }
    },
    CTAWrapperTabletModeWithMobileDesktop: {
        width: '100%',
        marginTop: 'auto',
        padding: 16,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            maxWidth: 310,
            marginTop: 16,
            marginRight: 'auto'
        },
        [theme.breakpoints.up('lg')]: {
            maxWidth: '100%',
            marginTop: 'auto',
            marginRight: 'unset'
        }
    },
    CTATextButton: {
        letterSpacing: '-0.04px',
        padding: 0,
        justifyContent: 'center',
        textTransform: 'none',
        color: theme.palette.text.secondary,
        fontSize: 14,
        fontWeight: 400,
        marginTop: 10,
        [theme.breakpoints.up('md')]: {
            textAlign: 'center'
        }
    },
    ellipsisWrap: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    },
    ellipsisWrapNoWidth: {
        '&, & > *': {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden'
        },
        flex: 1
    },
    preserveNewLine: {
        whiteSpace: 'pre'
    },
    preserveNewLineWrap: {
        whiteSpace: 'pre-wrap'
    },
    noWrap: {
        whiteSpace: 'nowrap'
    },
    backgroundPaper: {
        background: theme.palette.background.paper
    },
    hideOnDesktop: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    },
    hideOnMobile: {
        [theme.breakpoints.down('lg')]: {
            display: 'none'
        }
    },
    pointerCursor: {
        cursor: 'pointer'
    },
    disabled: {
        opacity: 0.5
    },
    noBoxShadow: { boxShadow: 'none' },
    bgApariPurple: { background: COLORS.apariPurple },
    tooltipDesign: {
        maxWidth: 235,
        padding: '8px 16px',
        backgroundColor: '#B7B7B7',
        color: COLORS.apariWhite,
        fontSize: 10,
        fontWeight: 600
    },
    arrowDesign: {
        color: '#B7B7B7'
    },
    lineHeight140: {
        lineHeight: '140%'
    },
    fontSize16: {
        fontSize: 16
    },
    errorColor: {
        color: COLORS.error
    },
    gap8: {
        gap: 8
    },
    disabledStyle: {
        opacity: 0.6,
        '& .MuiInput-underline::before': {
            borderBottomStyle: 'solid !important' as 'solid'
        },
        '& p, svg': {
            color: 'rgba(0, 0, 0, 0.38)'
        }
    }
}));
