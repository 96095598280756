import React, { useEffect, useState, PropsWithChildren } from 'react';
import { NotificationTypes, OpenNotificationsPayload } from 'types';
import { ConfigurationPublicControllerApi, MaintenanceAnnouncementDto } from '@apari/core-api';
import moment from 'moment';
import { defaultWhitelabelled, epOverrides } from 'utils/whitelabelling';
import { GlobalServices } from 'utils';
import { CLIENT } from '@apari-shared/constants';

const configurationPublicControllerApi = new ConfigurationPublicControllerApi();

type NotificationState = {
    showNotification: boolean;
    notificationType?: NotificationTypes;
    notificationMessage: string;
    onNotificationClose?: () => void;
};

interface AppContextInterface {
    loadingBar: boolean;
    showLoadingBar: () => void;
    hideLoadingBar: () => void;
    notificationState: NotificationState;
    showNotifications: (payload: OpenNotificationsPayload) => void;
    hideNotifications: () => void;
    showInfoBar: boolean;
    setShowInfoBar: React.Dispatch<React.SetStateAction<boolean>>;
    maintenanceAnnouncement?: MaintenanceAnnouncementDto;
    setMaintenanceAnnouncement: React.Dispatch<React.SetStateAction<MaintenanceAnnouncementDto | undefined>>;
    countdown: string;
    isOnline: boolean;
    whitelabelledApp: typeof defaultWhitelabelled;
    currentTaxYear?: string;
    setCurrentTaxYear: React.Dispatch<React.SetStateAction<string | undefined>>;
    aiChatWindowOpen: boolean;
    setAiChatWindowOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppContext = React.createContext({} as AppContextInterface);

export const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [isOnline, setIsOnline] = useState(true);
    const [loadingBar, setLoadingBar] = useState<boolean>(false);
    const [showInfoBar, setShowInfoBar] = useState(false);
    const [countdown, setCountdown] = useState('');
    const [maintenanceAnnouncement, setMaintenanceAnnouncement] = useState<MaintenanceAnnouncementDto>();
    const [notificationState, setNotificationState] = useState<NotificationState>({
        showNotification: false,
        notificationType: undefined,
        notificationMessage: '',
        onNotificationClose: undefined
    });
    const [whitelabelledApp, setWhitelabelledApp] = useState(defaultWhitelabelled);
    const [currentTaxYear, setCurrentTaxYear] = useState<string>();
    const [aiChatWindowOpen, setAiChatWindowOpen] = useState(false);

    let refreshInterval: NodeJS.Timeout;
    let secondsInterval: NodeJS.Timeout;
    let pingInterval: NodeJS.Timeout;

    useEffect(() => {
        const client = GlobalServices.getClient();
        let overrideWhitelabelledApp = {};
        switch (client) {
            case CLIENT.EP:
                overrideWhitelabelledApp = epOverrides;
        }

        setWhitelabelledApp({ ...defaultWhitelabelled, ...overrideWhitelabelledApp });
    }, []);

    useEffect(() => {
        handleConnectionChange();
        window.addEventListener('online', handleConnectionChange);
        window.addEventListener('offline', handleConnectionChange);

        return function cleanup() {
            window.removeEventListener('online', handleConnectionChange);
            window.removeEventListener('offline', handleConnectionChange);
        };
    }, []);

    useEffect(() => {
        checkForMaintenance();
        refreshInterval = setInterval(() => {
            checkForMaintenance();
        }, 600000);
        return function cleanup() {
            clearInterval(refreshInterval);
        };
    }, []);

    const handleConnectionChange = () => {
        const isOnline = window.navigator.onLine;
        if (isOnline) {
            pingGoogle();
            pingInterval = setInterval(() => {
                pingGoogle();
            }, 2000);
        } else {
            setIsOnline(false);
        }
    };

    const pingGoogle = () => {
        fetch('//google.com', {
            mode: 'no-cors'
        })
            .then(() => {
                setIsOnline(true);
                clearInterval(pingInterval as unknown as number);
            })
            .catch(() => setIsOnline(false));
    };

    const startTimer = (data: MaintenanceAnnouncementDto) => {
        clearInterval(secondsInterval);
        secondsInterval = setInterval(() => {
            const now = moment(new Date());
            const end = moment(data.startAt);
            const duration = moment.duration(end.diff(now));

            if (duration.asSeconds() < 0) {
                clearInterval(secondsInterval);
            }

            const sec_num = duration.asSeconds();
            let hours: any = Math.floor(sec_num / 3600);
            let minutes: any = Math.floor((sec_num - hours * 3600) / 60);
            let seconds: any = Math.floor(sec_num - hours * 3600 - minutes * 60);

            if (hours < 0) {
                clearInterval(secondsInterval);
                setShowInfoBar(true);
                setCountdown('00:00:00');
            } else if (hours > 0) {
                clearInterval(secondsInterval);
                setShowInfoBar(false);
                setCountdown('00:00:00');
            } else {
                if (hours < 10) {
                    hours = '0' + hours;
                }
                if (minutes < 10) {
                    minutes = '0' + minutes;
                }
                if (seconds < 10) {
                    seconds = '0' + seconds;
                }
                setCountdown(hours + ':' + minutes + ':' + seconds);
                setShowInfoBar(true);
            }
        }, 1000);
    };

    const checkForMaintenance = async () => {
        try {
            const response = await configurationPublicControllerApi.getMaintenanceAnnouncement();
            setMaintenanceAnnouncement(response.data);
            if (response.data.enable) {
                startTimer(response.data);
            } else {
                setShowInfoBar(false);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    const showLoadingBar = () => {
        setLoadingBar(true);
    };

    const hideLoadingBar = () => {
        setLoadingBar(false);
    };

    const showNotifications = (payload: OpenNotificationsPayload) => {
        setNotificationState({
            showNotification: true,
            notificationType: payload.type,
            notificationMessage: payload.message,
            onNotificationClose: payload.onClose
        });
    };

    const hideNotifications = () => {
        setNotificationState({
            showNotification: false,
            notificationType: undefined,
            notificationMessage: '',
            onNotificationClose: () => {
                //comment to prevent unexpected empty function
            }
        });
    };

    return (
        <AppContext.Provider
            value={{
                isOnline,
                countdown,
                maintenanceAnnouncement,
                setMaintenanceAnnouncement,
                showInfoBar,
                setShowInfoBar,
                loadingBar,
                showLoadingBar,
                hideLoadingBar,
                notificationState,
                showNotifications,
                hideNotifications,
                whitelabelledApp,
                currentTaxYear,
                setCurrentTaxYear,
                aiChatWindowOpen,
                setAiChatWindowOpen
            }}
        >
            {children}
        </AppContext.Provider>
    );
};
