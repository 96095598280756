import { makeStyles } from 'tss-react/mui';
import { GLOBAL } from 'constants/index';

type Props = {
    noWidthRestriction?: boolean;
    addHeight?: boolean;
};

export default makeStyles<Props>({ name: 'AnimatedTransition' })((theme, { noWidthRestriction, addHeight }) => ({
    root: {
        display: 'flex',
        flex: 1,
        marginTop: GLOBAL.TOP_MARGIN,
        [theme.breakpoints.up('lg')]: {
            maxWidth: noWidthRestriction ? '100%' : 780,
            width: '100%',
            position: 'relative'
        },
        [theme.breakpoints.down('lg')]: {
            width: '100%',
            height: addHeight ? '100%' : '',
            position: 'relative'
        }
    },
    centerContentVertically: {
        [theme.breakpoints.up('lg')]: {
            justifyContent: 'center'
        }
    }
}));
