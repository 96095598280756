import { Checkbox, CheckboxProps } from '@mui/material';
import clsx from 'clsx';
import CheckedIcon from './CheckedIcon';
import UncheckedIcon from './UncheckedIcon';
import styles from './styles';
import React from 'react';

type Props = CheckboxProps & {
    primaryBorderColor?: boolean;
    invertedColors?: boolean;
    iconColor?: string;
};

const ApariCheckbox: React.FC<Props> = ({ checked, disabled, size, iconColor, ...rest }) => {
    const { classes } = styles();

    return (
        <Checkbox
            className={clsx(classes.root)}
            icon={<UncheckedIcon disabled={disabled} color="secondary" />}
            checkedIcon={<CheckedIcon disabled={disabled} iconColor={iconColor} />}
            disabled={disabled}
            color="primary"
            checked={checked || false}
            value={checked || false}
            size={size ?? 'medium'}
            {...rest}
        />
    );
};

export default ApariCheckbox;
