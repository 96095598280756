import CryptoJS from 'crypto-js';

class EncryptionServices {
    static encrypt = (message = '', key = '', additionalOptions?: any[]): string => {
        const encryptedMessage = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message), CryptoJS.enc.Utf8.parse(key), {
            mode: CryptoJS.mode.ECB,
            ...additionalOptions
        });
        return encryptedMessage.toString();
    };

    static decrypt = (message = '', key = ''): string => {
        const code = CryptoJS.AES.decrypt(message, key, { mode: CryptoJS.mode.ECB });
        return code.toString(CryptoJS.enc.Utf8);
    };
}

export default EncryptionServices;
