const DATE_FORMAT = {
    DATE_OF_BIRTH_FORMAT: 'DD/MM/YYYY',
    UK_STANDARD: 'DD.MM.YYYY',
    'dd/MM/yyyy': 'dd/MM/yyyy',
    'dd.MM.YY': 'dd.MM.yy',
    'YYYY-MM-DD': 'YYYY-MM-DD',
    'MM-DD': 'MM-DD'
};

export default DATE_FORMAT;
