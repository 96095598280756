import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'PasswordStrengthMeter' })(() => ({
    passwordStrength: {
        marginBottom: '5.1px'
    },
    strongPasswordColor: {
        color: '#7CBD82'
    },
    mediumPasswordColor: {
        color: '#E8B23C'
    },
    weakPasswordColor: {
        color: '#D9455F'
    }
}));
