import React, { lazy, useContext, useEffect } from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { AuthRoute } from 'routers';
import { FullScreenLoading } from 'components';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { SettingsProvider } from 'context/SettingsContext';
import NotFoundScreen from 'containers/pages/user/NotFoundScreen';

const TFAMethodScreen = lazy(() => import('containers/pages/settings/TFAMethodScreen'));
const TFABindGoogleAuthenticatorScreen = lazy(() => import('containers/pages/settings/TFABindGoogleAuthenticatorScreen'));
const TFASafeRecoveryKeyScreen = lazy(() => import('containers/pages/settings/TFASafeRecoveryKeyScreen'));
const TFAGoogleAuthenticatorSetupCodeInputScreen = lazy(
    () => import('containers/pages/settings/TFAGoogleAuthenticatorSetupCodeInputScreen')
);
const TFALinkYourPhoneNumberScreen = lazy(() => import('containers/pages/settings/TFALinkYourPhoneNumberScreen'));
const TFACodeConfirmationScreen = lazy(() => import('containers/pages/settings/TFALinkYourPhoneNumberScreen/tfaCodeConfirmation'));

export const TWO_FACTOR_ROUTES = [
    '/two-factor',
    '/two-factor/bind-google-authenticator',
    '/two-factor/google-authentication-code',
    '/two-factor/safe-recovery-key',
    '/two-factor/sms-authentication-code',
    '/two-factor/link-your-phone-number'
];

const TwoFactorExistsRedirect: React.FC = () => {
    const { user, redirectionPrefix } = useContext(AuthenticationContext);

    useEffect(() => {
        if (user && user.twoFactorAuthMethod) window.location.href = redirectionPrefix + '/dashboard';
    }, [user]);

    if (!user || user.twoFactorAuthMethod) return <FullScreenLoading />;

    return <Outlet />;
};

const TwoFactorRouter: React.FC = () => {
    return (
        <SettingsProvider>
            <Routes>
                <Route element={<AuthRoute />}>
                    <Route element={<TwoFactorExistsRedirect />}>
                        <Route index element={<TFAMethodScreen />} />
                        <Route path="/bind-google-authenticator" element={<TFABindGoogleAuthenticatorScreen />} />
                        <Route path="/safe-recovery-key" element={<TFASafeRecoveryKeyScreen />} />
                        <Route path="/google-authentication-code" element={<TFAGoogleAuthenticatorSetupCodeInputScreen />} />
                        <Route path="/link-your-phone-number" element={<TFALinkYourPhoneNumberScreen />} />
                        <Route path="/sms-authentication-code" element={<TFACodeConfirmationScreen />} />
                    </Route>
                </Route>
                <Route path="*" element={<NotFoundScreen />} />
            </Routes>
        </SettingsProvider>
    );
};

export default TwoFactorRouter;
