import { FONTS } from 'constants/index';
import { COLORS } from '@apari-shared/constants';

import { makeStyles } from 'tss-react/mui';
import { useContext } from 'react';
import { AppContext } from 'context/AppContext';

type Props = {
    alternativeFont?: boolean;
    signUpScreen?: boolean;
    noTextColor?: boolean;
};

export default makeStyles<Props>({ name: 'SubHeaderClean' })((theme, { alternativeFont, signUpScreen, noTextColor }) => {
    const whitelabelledApp = useContext(AppContext).whitelabelledApp.styles.subHeaderClean;

    return {
        root: {},
        messageWrapper: {
            zIndex: 3,
            height: 'auto',
            borderRadius: '2px',
            transformOrigin: 'top',
            paddingLeft: 16,
            paddingRight: 16,
            [theme.breakpoints.up('lg')]: {
                maxWidth: 780,
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        messageWrapperBottomPadding: {
            paddingBottom: 20,
            ...(alternativeFont && {
                paddingBottom: 8
            }),
            ...(signUpScreen && {
                paddingBottom: 8,
                [theme.breakpoints.down('lg')]: {
                    paddingBottom: 0
                }
            })
        },
        subHeader: {
            alignSelf: 'flex-start',
            position: 'relative',
            zIndex: 1,
            backfaceVisibility: 'hidden',
            transform: 'translateZ(0)',
            transformOrigin: 'top',
            transition: 'transform 0.3s, margin-bottom 0s',
            WebkitTransition: 'transform 0.3s, margin-bottom 0s',
            MozTransition: 'transform 0.3s, margin-bottom 0s',
            MsTransition: 'transform 0.3s, margin-bottom 0s',
            OTransition: 'transform 0.3s, margin-bottom 0s',
            willChange: 'transform, margin-bottom'
        },
        titleWrapper: {
            marginBottom: 18,
            ...(alternativeFont && {
                marginBottom: 8
            })
        },
        titleText: {
            fontSize: 14,
            fontWeight: 600,
            color: COLORS.apariBlack80,
            whiteSpace: 'pre-wrap',
            ...(alternativeFont && {
                fontFamily: FONTS.TERTIARY_FONT,
                fontWeight: whitelabelledApp.titleText.fontWeight,
                fontSize: whitelabelledApp.titleText.fontSize,
                lineHeight: '56px',
                textTransform: whitelabelledApp.titleText.textTransform as unknown as any,
                color: whitelabelledApp.titleText.color,
                [theme.breakpoints.down('lg')]: {
                    fontSize: 48,
                    lineHeight: '44px'
                }
            })
        },
        backIconStyle: {
            fontSize: 32,
            color: theme.palette.text.secondary
        },
        message: {
            ...(!noTextColor && { color: COLORS.apariBlack80 }),
            fontSize: 18,
            fontWeight: 400,
            whiteSpace: 'pre-line',
            backfaceVisibility: 'hidden',
            transform: 'translateZ(0)',
            transformOrigin: 'left',
            transition: 'transform 0.3s, font-size 0.3s',
            WebkitTransition: 'transform 0.3s, font-size 0.3s',
            MozTransition: 'transform 0.3s, font-size 0.3s',
            MsTransition: 'transform 0.3s, font-size 0.3s',
            OTransition: 'transform 0.3s, font-size 0.3s',
            willChange: 'transform, font-size',
            ...(alternativeFont && {
                fontFamily: FONTS.QUATERNARY_FONT,
                fontWeight: 400,
                color: COLORS.apariBlack,
                fontSize: 16,
                [theme.breakpoints.up('md')]: {
                    whiteSpace: 'nowrap'
                }
            })
        }
    };
});
