class LocalStorageServices {
    static setJSON = (key: string, object: Record<string, unknown>): void => {
        try {
            localStorage.setItem(key, JSON.stringify(object));
        } catch (e) {
            console.log('err', e);
        }
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getJSON = (key: string): Record<string, any> | undefined => {
        try {
            const date = localStorage.getItem(key);
            if (date === null) {
                return undefined;
            }
            return JSON.parse(date);
        } catch (err) {
            return undefined;
        }
    };
    static removeItem = (key: string): void => {
        try {
            localStorage.removeItem(key);
        } catch (e) {
            console.log('err', e);
        }
    };
}

export default LocalStorageServices;
