export default [
    '/settings/personal-data',
    '/settings/account-settings',
    '/settings/account-settings/confirm-2fa',
    '/settings/account-settings/bind-google-authenticator',
    '/settings/account-settings/google-authentication-code',
    '/settings/account-settings/safe-recovery-key',
    '/settings/account-settings/link-your-phone-number',
    '/settings/account-settings/sms-authentication-code',
    '/settings/account-settings/cancel-deletion',
    '/two-factor',
    '/two-factor/bind-google-authenticator',
    '/two-factor/google-authentication-code',
    '/two-factor/safe-recovery-key',
    '/two-factor/sms-authentication-code',
    '/two-factor/link-your-phone-number'
];
