import moment from 'moment';

class DateTimeServices {
    static getDateInGivenFormat(inputDate: Date | string | undefined, format: string, parseFormat?: string): string {
        const date = moment(inputDate, parseFormat);

        if (inputDate && date.isValid()) {
            return moment(date).local().format(format);
        } else {
            return '';
        }
    }

    static isValid(date: Date | string): boolean {
        return !date ? false : moment(date).isValid();
    }
}

export default DateTimeServices;
