export enum TokenErrorTypes {
    INVALID,
    EXPIRED
}

export enum TOUR_TYPES {
    MENU = 'MENU',
    MENU_MOBILE_FULL = 'MENU_MOBILE_FULL',
    MENU_MOBILE = 'MENU_MOBILE'
}

export enum AccountType {
    INDIVIDUAL,
    ACCOUNTANT
}

export type ToursStatus = Record<string, boolean | undefined>;
