import { TaxYearControllerApi } from '@apari/uk-jf-api';

const taxYearControllerApi = new TaxYearControllerApi();

class SubmissionServices {

    static getCurrentTaxYear = async (): Promise<string | undefined> => {
        try {
            const response = await taxYearControllerApi.getCurrentTaxYear();
            const { data } = response;
            return data;
        } catch (error) {
            console.error(error);
        }
    };

}

export default SubmissionServices;
