import { ApariButton } from '@apari-shared/components';
import { AiContext } from 'context/AiContext';
import { useContext } from 'react';
import { Trans } from 'react-i18next';
import styles from './styles';

const MessageRejectedBody = () => {
    const { classes } = styles();

    const { setDisclaimerActive } = useContext(AiContext);

    return (
        <Trans i18nKey="AI_ASSISTANT.MESSAGE_VIOLATION">
            I think this may violate our content rules which you find here{' '}
            <ApariButton
                variant="text"
                disableTouchRipple
                classes={{ root: classes.violationPolicyBtn }}
                onClick={() => setDisclaimerActive(true)}
            >
                Violation policy.
            </ApariButton>{' '}
            If you believe this to be an error, please rate this chat box and leave me some feedback. Please continue with a different
            wording or file.
        </Trans>
    );
};

export default MessageRejectedBody;
