import { makeStyles } from 'tss-react/mui';
import { useContext } from 'react';
import { AppContext } from '../../../../context/AppContext';

type Props = {
    inDialog?: boolean;
};
export const useStyles = makeStyles<Props, 'switchDisabled' | 'onSwitch' | 'offSwitch'>({ name: 'App' })((theme, { inDialog }, classes) => {
    const styles = useContext(AppContext).whitelabelledApp.styles.apariTextSwitch;

    const rounding = inDialog ? 0 : styles.rounding;

    return {
        container: {
            height: '24px'
        },
        switch: {
            position: 'relative',
            display: 'inline-block',
            width: '78px',
            height: '24px'
        },
        hiddenInput: {
            display: 'none'
        },
        slider: {
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: styles.falseValueBackgroundColorEnabled,
            transition: '0.4s',
            borderRadius: rounding,
            [`&.${classes.switchDisabled}`]: {
                opacity: 0.7,
                cursor: 'default',
                backgroundColor: styles.falseValueBackgroundColorDisabled
            },
            '&:before': {
                position: 'absolute',
                content: '""',
                height: '22px',
                width: '38px',
                left: '1px',
                bottom: '1px',
                backgroundColor: 'white',
                transition: '0.4s',
                borderRadius: rounding
            },
            '& input:checked + &': {
                backgroundColor: theme.palette.primary.main
            },
            '& input:focus + &': {
                boxShadow: '0 0 1px #2196f3'
            },
            '& input:checked + & .onSwitch': {
                display: 'block'
            },
            '& input:checked + & .offSwitch': {
                display: 'none'
            }
        },
        sliderChecked: {
            backgroundColor: theme.palette.primary.main,
            '&:before': {
                transform: 'translateX(38px)'
            },
            [`& .${classes.onSwitch}`]: {
                display: 'block'
            },
            [`& .${classes.offSwitch}`]: {
                display: 'none'
            }
        },
        onSwitch: {
            display: 'none',
            color: 'white',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '12px',
            left: '25%',
            fontSize: '14px',
            fontFamily: 'Verdana, sans-serif'
        },
        offSwitch: {
            color: 'white',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '12px',
            left: 'auto',
            right: 0,
            fontSize: '14px',
            fontFamily: 'Verdana, sans-serif'
        },
        switchDisabled: {}
    };
});
