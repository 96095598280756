import { COLORS } from '@apari-shared/constants';
import { ButtonPropsColorOverrides, ButtonPropsVariantOverrides, SvgIconPropsColorOverrides } from '@mui/material';
import { OverridableStringUnion } from '@mui/types';
import {
    ApariArrowBig,
    ApariArrowSmall,
    ApariLogoPurple,
    ApariLogoWhite,
    EPCondensedLogo,
    EPGradientBg,
    EPLogo,
    EPWhiteGoldLogo,
    EpLaptopAndMobile,
    LaptopAndMobile,
    PurpleBackgroundRight
} from 'resources/images';
import theme from 'styles/theme';

export const defaultWhitelabelled = {
    assets: {
        fullScreenLoading: {
            logo: ApariLogoPurple
        },
        desktopNavigationMenu: {
            logo: ApariLogoWhite,
            logoHeight: 32
        },
        header: {
            logo: ApariLogoPurple,
            alternateLogo: ApariLogoPurple,
            useLightText: false
        },
        footer: {
            footerLogo: ApariLogoPurple
        },
        accountSettingsScreen: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        forgotPasswordDialog: {
            showImg: true,
            imgSrc: ApariArrowSmall
        },
        newPasswordDialog: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        setUpPasswordDialog: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        confirmationDialog: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        emailSentDialog: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        useRecoveryKeyDialog: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        accountDeletionCancellationScreen: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        deleteAccountDialog: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        personalDataScreen: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        qualificationErrorDialog: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        updateUserAddressForm: {
            showImg: true,
            imgSrc: ApariArrowBig
        },
        addOrEditSelfEmployedBusinessForm: {
            showImg: true,
            imgSrc: ApariArrowBig
        }
    },
    styles: {
        general: {
            headerBackgroundColor: theme.palette.background.paper,
            menuBorderRadius: '5px',
            useLightTextHeader: false,
            hideSubscriptionStatusBorder: false
        },
        desktopNavigation: {
            icons: {
                color: <
                    | OverridableStringUnion<
                          'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          SvgIconPropsColorOverrides
                      >
                    | undefined
                >'inherit'
            }
        },
        layout: {
            general: {
                backgroundImage: `url(${PurpleBackgroundRight}), url(${ApariArrowBig})`,
                backgroundRepeat: 'no-repeat, no-repeat',
                backgroundSize: 'auto 100%, auto 340px',
                backgroundPosition: 'right top, left center',
                downLg: {
                    backgroundImage: 'none',
                    backgroundSize: 0
                }
            },
            laptopAndMobileImage: {
                backgroundImage: `url(${LaptopAndMobile}), url(${PurpleBackgroundRight}), url(${ApariArrowBig})`,
                backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
                backgroundSize: 'auto 380px, auto 100%, auto 340px',
                backgroundPosition: 'top 63% right 88px, right top, left center',
                betweenMdAndXl: {
                    backgroundSize: 'auto 290px, 50% 100%, auto 340px',
                    backgroundPosition: 'top 50% right 88px, right top, left center'
                },
                downLg: {
                    backgroundImage: 'none',
                    backgroundSize: '',
                    backgroundPosition: ''
                },
                up2100: {
                    backgroundSize: 'auto 600px, auto 100%, auto 340px',
                    backgroundPosition: 'top 63% right 15%, right top, left center'
                }
            },
            floatingActionMenu: {
                floatingMenuBtn: {
                    borderRadius: 10,
                    padding: '8px 12px'
                }
            }
        },
        subHeaderClean: {
            titleText: {
                textTransform: 'uppercase',
                fontSize: 64,
                fontWeight: 800,
                color: COLORS.apariBlack
            }
        },
        authScreens: {
            hideHeader: false,
            showBackToWebsite: false,
            input: {
                color: '',
                borderColor: '',
                borderRadius: '',
                label: {
                    color: ''
                },
                focused: {
                    borderColor: ''
                }
            },
            passwordField: {
                iconColor: <
                    | OverridableStringUnion<
                          'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          ButtonPropsColorOverrides
                      >
                    | undefined
                >'inherit'
            },
            showLogo: false,
            logoSrc: '',
            logoHeight: 0
        },
        signUpWithEmail: {
            checkIcon: {
                color: theme.palette.primary.main
            },
            text: {
                color: COLORS.apariBlack
            },
            submitButton: {
                variant: <OverridableStringUnion<'contained' | 'text' | 'outlined', ButtonPropsVariantOverrides> | undefined>'contained',
                color: <
                    | OverridableStringUnion<
                          'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          ButtonPropsColorOverrides
                      >
                    | undefined
                >'primary',
                disabled: {
                    borderColor: '',
                    textColor: ''
                },
                borderColor: '',
                textColor: ''
            }
        },
        signInScreen: {
            mainContentWidth: {
                maxWidth: 'none',
                width: 'auto'
            },
            text: {
                color: COLORS.apariBlack
            },
            resetPassword: {
                color: COLORS.apariBlack80
            },
            submitButton: {
                variant: <OverridableStringUnion<'contained' | 'text' | 'outlined', ButtonPropsVariantOverrides> | undefined>'contained',
                color: <
                    | OverridableStringUnion<
                          'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          ButtonPropsColorOverrides
                      >
                    | undefined
                >'primary'
            }
        },
        forgotPasswordScreen: {
            text: {
                color: COLORS.apariBlack
            },
            acceptTerms: {
                color: COLORS.apariBlack80
            },
            checkbox: {
                color: theme.palette.primary.main
            }
        },
        accountSettingsScreen: {
            editOutlinedIcon: {
                color: COLORS.primary
            },
            checkbox: {
                color: theme.palette.primary.main
            },
            cancelButton: {
                backgroundColor: COLORS.backgroundDark
            },
            showNewsLetterOpt: true
        },
        accountConfirmationSuccessScreen: {
            text: {
                color: COLORS.apariBlack
            }
        },
        tfaCodeInputScreen: {
            submitButton: {
                color: theme.palette.primary.main
            }
        },
        apSelectField: {
            card: {
                borderRadius: 2
            }
        },
        tfaMethodScreen: {
            button: {
                fontWeight: 400
            }
        },
        otpInput: {
            background: COLORS.apariWhite,
            color: COLORS.apariBlack80,
            borderBottom: '2px solid ' + COLORS.apariBlack20,
            fontWeight: 400
        },
        apariTextSwitch: {
            rounding: 3,
            falseValueBackgroundColorEnabled: '#bbbbbb',
            falseValueBackgroundColorDisabled: '#bbbbbb'
        },
        submissionFormFieldWrapper: {
            borderLeftColor: COLORS.apariLightSilver,
            borderLeftDisabledColor: COLORS.apariLightSilver,
            borderLeftWidth: '5px'
        }
    },
    externalLinks: {
        helpCenter: 'https://www.apari-digital.com/help-centre',
        privacyPolicy: 'https://www.apari-digital.com/privacy-policy',
        supportMail: 'support@apari-digital.com',
        contact: 'https://www.apari-digital.com/contact-help-desk',
        termsAndConditions: 'https://www.apari-digital.com/terms-and-conditions',
        signUpWithEmailScreen: {
            privacyPolicy: 'https://www.apari-digital.com/privacy-policy',
            termsAndConditions: 'https://www.apari-digital.com/terms-and-conditions'
        }
    }
};

export const epOverrides = {
    ...defaultWhitelabelled,
    assets: {
        fullScreenLoading: {
            logo: EPLogo
        },
        desktopNavigationMenu: {
            logo: EPCondensedLogo,
            logoHeight: 36
        },
        header: {
            logo: theme.breakpoints.down('md') ? EPWhiteGoldLogo : EPLogo,
            alternateLogo: EPWhiteGoldLogo,
            useLightText: true
        },
        footer: {
            footerLogo: EPLogo
        },
        accountSettingsScreen: {
            showImg: false
        },
        forgotPasswordDialog: {
            showImg: false
        },
        newPasswordDialog: {
            showImg: false
        },
        setUpPasswordDialog: {
            showImg: false
        },
        confirmationDialog: {
            showImg: false
        },
        emailSentDialog: {
            showImg: false
        },
        useRecoveryKeyDialog: {
            showImg: false
        },
        accountDeletionCancellationScreen: {
            showImg: false
        },
        deleteAccountDialog: {
            showImg: false
        },
        personalDataScreen: {
            showImg: false
        },
        qualificationErrorDialog: {
            showImg: false
        },
        updateUserAddressForm: {
            showImg: false
        },
        addOrEditSelfEmployedBusinessForm: {
            showImg: false
        }
    },
    styles: {
        general: {
            headerBackgroundColor: theme.palette.primary.main,
            menuBorderRadius: '2px',
            useLightTextHeader: true,
            hideSubscriptionStatusBorder: theme.breakpoints.down('md')
        },
        desktopNavigation: {
            icons: {
                color: <
                    | OverridableStringUnion<
                          'disabled' | 'action' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          SvgIconPropsColorOverrides
                      >
                    | undefined
                >'secondary'
            }
        },
        layout: {
            general: {
                backgroundImage: `url(${EPGradientBg}), linear-gradient(261deg, #653387 1.2%, #380C57 90.21%)`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover, 100%',
                backgroundPosition: 'bottom left',
                downLg: {
                    backgroundImage: 'linear-gradient(261deg, #653387 1.2%, #380C57 90.21%)',
                    backgroundSize: '100%'
                }
            },
            laptopAndMobileImage: {
                backgroundImage: `url(${EpLaptopAndMobile}), url(${EPGradientBg}), linear-gradient(261deg, #653387 1.2%, #380C57 90.21%)`,
                backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
                backgroundSize: 'auto 380px, cover, 100%',
                backgroundPosition: 'top 50% right 88px, bottom left, bottom left',
                betweenMdAndXl: {
                    backgroundSize: 'auto 290px, cover, 100%',
                    backgroundPosition: 'top 50% right 88px, bottom left, bottom left'
                },
                downLg: {
                    backgroundImage: `linear-gradient(261deg, #653387 1.2%, #380C57 90.21%)`,
                    backgroundSize: '100%',
                    backgroundPosition: 'bottom left'
                },
                up2100: {
                    backgroundSize: 'auto 600px, cover, 100%',
                    backgroundPosition: 'top 63% right 15%, bottom left, bottom left'
                }
            },
            floatingActionMenu: {
                floatingMenuBtn: {
                    borderRadius: 50,
                    padding: '6.5px 24px'
                }
            }
        },
        subHeaderClean: {
            titleText: {
                textTransform: 'unset',
                fontSize: 45,
                fontWeight: 500,
                color: COLORS.apariWhite
            }
        },
        authScreens: {
            hideHeader: true,
            showBackToWebsite: false,
            backgroundColor: 'linear-gradient(261deg, #653387 1.2%, #380C57 90.21%)',
            input: {
                color: COLORS.apariWhite,
                borderColor: 'rgba(255, 255, 255, 0.85)',
                borderRadius: '2px',
                label: {
                    color: theme.palette.secondary.main
                },
                focused: {
                    borderColor: theme.palette.secondary.main
                }
            },
            passwordField: {
                iconColor: <
                    | OverridableStringUnion<
                          'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          ButtonPropsColorOverrides
                      >
                    | undefined
                >'secondary'
            },
            showLogo: true,
            logoSrc: EPCondensedLogo,
            logoHeight: 102
        },
        signUpWithEmail: {
            checkIcon: {
                color: theme.palette.secondary.main
            },
            text: {
                color: COLORS.apariWhite
            },
            submitButton: {
                variant: <OverridableStringUnion<'contained' | 'text' | 'outlined', ButtonPropsVariantOverrides> | undefined>'outlined',
                color: <
                    | OverridableStringUnion<
                          'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          ButtonPropsColorOverrides
                      >
                    | undefined
                >'inherit',
                disabled: {
                    borderColor: 'rgba(255, 255, 255, 50%)',
                    textColor: 'rgba(255, 255, 255, 50%)'
                },
                borderColor: COLORS.apariWhite,
                textColor: COLORS.apariWhite
            }
        },
        signInScreen: {
            mainContentWidth: {
                maxWidth: 450,
                width: '100%'
            },
            text: {
                color: COLORS.apariWhite
            },
            resetPassword: {
                color: COLORS.apariWhite
            },
            submitButton: {
                variant: <OverridableStringUnion<'contained' | 'text' | 'outlined', ButtonPropsVariantOverrides> | undefined>'outlined',
                color: <
                    | OverridableStringUnion<
                          'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
                          ButtonPropsColorOverrides
                      >
                    | undefined
                >'inherit'
            }
        },
        forgotPasswordScreen: {
            text: {
                color: COLORS.apariWhite
            },
            acceptTerms: {
                color: COLORS.apariWhite
            },
            checkbox: {
                color: theme.palette.secondary.main
            }
        },
        accountSettingsScreen: {
            editOutlinedIcon: {
                color: theme.palette.secondary.main
            },
            checkbox: {
                color: theme.palette.secondary.main
            },
            cancelButton: {
                backgroundColor: 'transparent'
            },
            showNewsLetterOpt: false
        },
        accountConfirmationSuccessScreen: {
            text: {
                color: COLORS.apariWhite
            }
        },
        tfaCodeInputScreen: {
            submitButton: {
                color: COLORS.apariWhite
            }
        },
        apSelectField: {
            card: {
                borderRadius: 8
            }
        },
        tfaMethodScreen: {
            button: {
                fontWeight: 600
            }
        },
        otpInput: {
            background: 'transparent',
            color: COLORS.apariWhite,
            borderBottom: '2px solid ' + COLORS.apariWhite,
            fontWeight: 500
        },
        apariTextSwitch: {
            rounding: 100,
            falseValueBackgroundColorEnabled: '#828282',
            falseValueBackgroundColorDisabled: '#CCCCCC'
        },
        submissionFormFieldWrapper: {
            borderLeftColor: '#00AFA9',
            borderLeftDisabledColor: COLORS.apariLightSilver,
            borderLeftWidth: '3px'
        }
    },
    externalLinks: {
        helpCenter: 'https://www.evelyn.com/digital-tax-returns/help',
        privacyPolicy: 'https://www.evelyn.com/legal-compliance-regulatory/privacy-notices/',
        supportMail: 'support@digitaltax.evelyn.com',
        contact: 'mailto:support@digitaltax.evelyn.com',
        termsAndConditions: 'https://www.evelyn.com/digital-tax-returns/help/terms-conditions/',
        signUpWithEmailScreen: {
            privacyPolicy: 'https://www.evelyn.com/digital-tax-returns/help/terms-conditions/',
            termsAndConditions: 'https://www.evelyn.com/legal-compliance-regulatory/privacy-notices/'
        }
    }
};
