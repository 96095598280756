import { makeStyles } from 'tss-react/mui';
import { COLORS } from '@apari-shared/constants';

export default makeStyles({ name: 'ApSelectField' })({
    formControl: {
        '& label': {
            color: COLORS.apariBlack80
        }
    },
    labelShrink: {
        transform: 'translate(14px, -7px) scale(0.75)'
    },
    select: {
        '& svg': {
            top: 'auto',
            right: 14,
            color: COLORS.apariBlack80
        },
        '&:before': {
            borderColor: 'red'
        },
        height: 36
    },
    menuItem: {
        fontSize: 13
    },
    lastMenuItem: {
        borderTopWidth: 'thin',
        borderTop: `1px solid ${COLORS.apariBlack10}`,
        fontWeight: 500,
        paddingTop: '8px',
        paddingBottom: '8px'
    }
});
