import React from 'react';
import clsx from 'clsx';
import OtpInput, { OtpInputProps } from 'react18-input-otp';
import styles from './styles';

const ApariOTPInput: React.FC<OtpInputProps> = props => {
    const { classes } = styles({ confirm2faScreen: window.location.pathname.includes('/account-settings') });

    return <OtpInput inputStyle={clsx(classes.input)} containerStyle={clsx(classes.inputWrapper)} {...props} />;
};

export default ApariOTPInput;
