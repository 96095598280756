import React, { PropsWithChildren } from 'react';
import clsx from 'clsx';
import styles from './styles';

type ApariSelectFieldGridProps = {
    className?: string;
};

const ApariSelectFieldGrid: React.FC<PropsWithChildren<ApariSelectFieldGridProps>> = ({ className, children }) => {
    const { classes } = styles();
    return <div className={clsx(classes.checkBoxOptionsGrid, className)}>{children}</div>;
};

export default ApariSelectFieldGrid;
