import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectProps } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import styles from './styles';

interface Option {
    label: string;
    value: string | number;
    disabled?: boolean;
    isGroupTitle?: boolean;
    path?: string;
}

interface Props {
    options: Option[] | undefined;
    control: string;
    label?: any;
    value?: any;
    error?: boolean;
    helperText?: string | boolean;
    defaultValue?: string | number;
    formControlClassName?: string;
    shrink?: boolean;
}

const ApSelectField: React.FC<Props & SelectProps> = ({
    control,
    value,
    label = '',
    options,
    error,
    helperText,
    formControlClassName,
    variant,
    shrink,
    disabled,
    ...rest
}) => {
    const labelId = `label_${control}`;
    const inputLabel = React.useRef<HTMLLabelElement>(null);

    const { classes } = styles();
    const navigate = useNavigate();

    useEffect(() => {
        if (value === 'path') {
            const path = options?.find(option => option.value === 'path')?.path;
            if (path) {
                navigate(path);
            }
        }
    }, [value]);

    return (
        <FormControl
            variant={variant || 'outlined'}
            margin="dense"
            error={!!error}
            disabled={disabled}
            fullWidth
            className={clsx(classes.formControl, formControlClassName)}
        >
            <InputLabel
                ref={inputLabel}
                shrink={shrink}
                id={labelId}
                style={{ fontSize: 13 }}
                variant={variant || 'outlined'}
                classes={{ shrink: classes.labelShrink }}
            >
                {label}
            </InputLabel>
            <Select
                className={clsx(classes.select)}
                id={control}
                value={value}
                name={control}
                labelId={labelId}
                label={label}
                style={{ fontSize: 16 }}
                variant={variant || 'outlined'}
                disabled={disabled}
                IconComponent={ExpandMoreIcon}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }}
                {...rest}
            >
                {options &&
                    options.map((option: Option) => (
                        <MenuItem
                            data-cy={option.label.split(' ').join('')}
                            key={uuidv4()}
                            value={option.value}
                            disabled={option.isGroupTitle || option.disabled}
                            className={clsx(classes.menuItem, option.path ? classes.lastMenuItem : null)}
                        >
                            {option.label}
                        </MenuItem>
                    ))}
            </Select>

            <FormHelperText>{helperText}</FormHelperText>
        </FormControl>
    );
};

export default ApSelectField;
