import { COLORS } from '@apari-shared/constants';
import { makeStyles } from 'tss-react/mui';
import { AppContext } from 'context/AppContext';
import { useContext } from 'react';

export default makeStyles({ name: 'ApariSelectField' })(theme => {
    const whitelabelledStyles = useContext(AppContext).whitelabelledApp.styles.apSelectField;

    return {
        card: {
            borderRadius: whitelabelledStyles.card.borderRadius,
            background: theme.palette.background.paper,
            padding: '15px 12px',
            width: '100%',
            boxShadow:
                '0px 0.51744px 8.11312px 0px rgba(0, 0, 0, 0.02), 0px 1.28121px 27.2503px 0px rgba(0, 0, 0, 0.02), 0px 4px 122px 0px rgba(0, 0, 0, 0.04)',
            '&:hover': {
                borderColor: COLORS.transparentLight
            }
        },
        cardChecked: {
            border: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            '&:hover': {
                borderColor: theme.palette.primary.main
            }
        },
        checkboxIcon: {
            width: 32,
            height: 32,
            borderRadius: 4,
            border: 2,
            fill: 'none',
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main
        },
        disabled: {
            borderColor: theme.palette.grey[500]
        },
        text: {
            paddingTop: 3
            //    wordBreak: 'break-all',
        },
        textDisabled: {
            color: theme.palette.grey[500]
        }
    };
});
