import React, { useContext } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { PaymentStatusDtoStatusEnum } from '@apari/core-api';
import { RoleNames } from '@apari-shared/utils';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { INACTIVE_SUBSCRIPTION_ENABLED_ROUTES } from 'constants/index';

const AuthRoute: React.FC = () => {
    const location = useLocation();

    const { paymentStatus, user, authenticated, redirectionPrefix } = useContext(AuthenticationContext);

    const acceptedTermsAndConditions = Boolean(user.acceptedTermsAndConditions);
    const onboardingIsDone = Boolean(user.sa100WizardDone);
    const inactiveSubscription = paymentStatus?.status === PaymentStatusDtoStatusEnum.INACTIVE;
    const isAgent = user?.role?.roleName === RoleNames.AGENT;
    const redirectUsersWithInactivePayment =
        inactiveSubscription && !INACTIVE_SUBSCRIPTION_ENABLED_ROUTES.includes(location.pathname as string);

    const getRedirectionRoutesByConditions = () => {
        if (!authenticated) {
            return <Navigate replace to="/sign-in" />;
        } else if (isAgent) {
            return <Navigate replace to="/redirect/agent" />;
        } else if (!acceptedTermsAndConditions) {
            return <Navigate replace to="/accept-terms-and-conditions" />;
        } else if (!onboardingIsDone) {
            window.location.href = redirectionPrefix + '/onboarding';
            return <div />;
        } else if (redirectUsersWithInactivePayment) {
            window.location.href = redirectionPrefix + '/dashboard';
            return <div />;
        } else {
            return <Outlet />;
        }
    };

    return getRedirectionRoutesByConditions();
};

export default AuthRoute;
