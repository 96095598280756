import { every, reduce, upperCase } from 'lodash';
import { v4 as uuidv4, validate as uuidValidate } from 'uuid';
import Cookies from 'universal-cookie/lib/Cookies';
import { GLOBAL, COMMONS, RESPONSE_CODES } from 'constants/index';
import { Empty, DetermineEmptyValueType, TokenErrorTypes } from 'types';
import { Localisation } from 'utils';
import { CLIENT } from '@apari-shared/constants';

class GlobalServices {
    static isDefined<T>(value: T | undefined | null): value is T {
        return value !== undefined && value !== null;
    }

    static definedValueIsEmpty<T extends number | boolean | string | { [key: string]: any } | any[]>(
        value: T | Empty
    ): value is DetermineEmptyValueType<T> {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        switch (typeof value) {
            case 'object':
                return Object.keys(value).length === 0;
            case 'string':
                return value === '';
            default:
                return false;
        }
    }

    static isEmpty<T extends number | string | { [key: string]: any } | any[]>(value: T | null | boolean | undefined): value is never {
        // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.isDefined(value)) {
            return this.definedValueIsEmpty(value);
        }
        return true;
    }

    static getQueryParameterIfItExists(searchParams: URLSearchParams, param: string): string | undefined {
        return searchParams.has(param) ? (searchParams.get(param) as string) : undefined;
    }

    static timeout(delay: number): Promise<void> {
        return new Promise(res => setTimeout(res, delay));
    }

    static toCamelCase(s: string) {
        return s.replace(/([-_][a-z])/gi, $1 => {
            return $1.toUpperCase().replace('-', '').replace('_', '');
        });
    }

    static keysToCamelCase(obj: Record<string, any>) {
        const n: Record<string, unknown> = {};

        Object.keys(obj).forEach(k => {
            n[GlobalServices.toCamelCase(k)] = obj[k];
        });

        return n;
    }

    static responseCodesErrorHandler(data: any): any {
        let errorHandler;
        if (data.responseCode === RESPONSE_CODES.USER_ALREADY_EXISTS) {
            errorHandler = Localisation.localize('serverErrors.userAlreadyExists');
        } else if (data.responseCode === RESPONSE_CODES.BAD_CREDENTIALS) {
            errorHandler = Localisation.localize('serverErrors.badCredentials');
        } else if (data.responseCode === RESPONSE_CODES.USER_NOT_CONFIRMED) {
            errorHandler = Localisation.localize('serverErrors.userNotConfirmed');
        } else if (data.responseCode === RESPONSE_CODES.INVALID_TOKEN) {
            errorHandler = { tokenErrorType: TokenErrorTypes.INVALID };
        } else if (data.responseCode === RESPONSE_CODES.EXPIRED_TOKEN) {
            errorHandler = { tokenErrorType: TokenErrorTypes.EXPIRED };
        } else if (data.responseCode === RESPONSE_CODES.INVALID_TWO_FACTOR_CODE) {
            errorHandler = Localisation.localize('serverErrors.wrongCode');
        } else if (data.responseCode === RESPONSE_CODES.OAUTH_USER_ALREADY_EXITS) {
            errorHandler = { userAlreadyExists: true };
        } else if (data.responseCode === RESPONSE_CODES.STRIPE_ERROR) {
            errorHandler = Localisation.localize('serverErrors.stripeError');
        } else if (data.responseCode === RESPONSE_CODES.CARD_ERROR) {
            errorHandler = Localisation.localize('serverErrors.cardError');
        } else if (data.responseCode === RESPONSE_CODES.RECOVERY_CODE_USED_ON_ACTIVATION) {
            errorHandler = Localisation.localize('serverErrors.youUsedRecoveryKey');
        } else if (data.responseCode === RESPONSE_CODES.CANT_RESET_PASSWORD_FOR_THIS_EMAIL) {
            errorHandler = Localisation.localize('serverErrors.CANT_RESET_PASSWORD_FOR_THIS_EMAIL');
        } else if (data.responseCode === RESPONSE_CODES.ALREADY_REGISTERED_USING_EMAIL) {
            errorHandler = { registeredUsingEmail: true, message: Localisation.localize('serverErrors.ALREADY_REGISTERED_USING_EMAIL') };
        } else if (data.responseCode === RESPONSE_CODES.ACCOUNT_ALREADY_CONFIRMED) {
            errorHandler = {
                accountAlreadyConfirmed: true,
                message: Localisation.localize('serverErrors.accountAlreadyConfirmed')
            };
        } else if (data.responseCode === RESPONSE_CODES.TWO_FACTOR_AUTHENTICATION_REQUIRED) {
            errorHandler = { twoFactorRequired: true, data: JSON.parse(data.responseMessage) };
        } else if (data.message === 'Webform required') {
            errorHandler = { ...data };
        } else if (data.responseCode === RESPONSE_CODES.HMRC_AUTHORIZATION_REQUIRED) {
            errorHandler = { hmrcAuthorizationRequired: true, data: JSON.parse(data.responseMessage) };
        } else if (RESPONSE_CODES[data.responseCode]) {
            // TODO: Add the rest of response codes to the list in same way as for this one
            errorHandler = { ...data, responseMessage: Localisation.localize(`serverErrors.${RESPONSE_CODES[data.responseCode]}`) };
        } else {
            errorHandler = !GlobalServices.isEmpty(data) ? data : Localisation.localize('serverErrors.problemWithResource');
        }
        return errorHandler;
    }

    static getUUID(): string {
        const cookie = new Cookies();
        const uuid = cookie.get(GLOBAL.UUID_COOKIE_KEY);

        if (uuidValidate(uuid)) {
            return uuid;
        } else {
            const generatedUUID = uuidv4();
            cookie.set(GLOBAL.UUID_COOKIE_KEY, generatedUUID);
            return generatedUUID;
        }
    }

    static checkHowManyRegexRulesAreMet = (passwordRegex: RegExp[], value: any) => {
        return reduce(
            passwordRegex,
            (sum, rule) => {
                return value.match(rule) ? sum + 1 : sum;
            },
            0
        );
    };

    static isHiddenFeaturesEnabled(): boolean {
        return localStorage.getItem(COMMONS.LOCAL_STORAGE_KEYS.enableHiddenFeatures) === 'true';
    }

    static camelCaseToSnakeUpperCase = (camelCaseString: string) => {
        return upperCase(camelCaseString).replace(/ /g, '_');
    };

    static generateErrorMessage = (error: any) => {
        let message = error;
        if (error?.responseMessage) {
            message = error?.responseMessage;
        } else if (error?.message) {
            message = error?.message;
        } else if (error?.code >= 500) {
            message = Localisation.localize('serverErrors.SOMETHING_WENT_WRONG');
        }
        return message;
    };

    static getClient = () => {
        if (window.location.href.includes('evelyn')) {
            return CLIENT.EP;
        } else {
            return CLIENT.APARI;
        }
    };

    static areAllKeysEmpty = (obj: { [key: string]: any }): boolean => {
        return every(obj, value => value === '' || value === null || value === undefined);
    };
}

export default GlobalServices;
