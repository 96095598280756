import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { RedirectionPrefix } from '@apari-shared/utils';
import { AuthenticationContext } from 'context/AuthenticationContext';

const PublicRoute: React.FC = () => {
    const { authenticated, redirectionPrefix } = useContext(AuthenticationContext);

    if (authenticated) {
        if (redirectionPrefix === RedirectionPrefix.SA) {
            return <Navigate replace to={redirectionPrefix + '/dashboard'} />;
        } else {
            window.location.href = redirectionPrefix + '/dashboard';
            return null;
        }
    } else {
        return <Outlet />;
    }
};

export default PublicRoute;
