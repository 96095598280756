import { FetchStatus } from './api';
import type { ChatMessage } from '@apari-shared/components';

export { ChatMessage };

export type FileUpload = {
    id: string | null;
    file: File;
    status: FetchStatus;
};

export enum ThumbEnum {
    UP = 'UP',
    DOWN = 'DOWN'
}
