class UIServices {
    static isMobile(width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'): boolean {
        switch (width) {
            case 'xs':
                return true;
            case 'sm':
                return true;
            case 'md':
                return true;
            case 'lg':
                return false;
            case 'xl':
                return false;
            default:
                return false;
        }
    }

    static waitForElement(selector: string) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(() => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }
}

export default UIServices;
