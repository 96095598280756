import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthRoute } from 'routers';
import { SettingsProvider } from 'context/SettingsContext';
import NotFoundScreen from 'containers/pages/user/NotFoundScreen';

const PersonalDataScreen = lazy(() => import('containers/pages/settings/PersonalDataScreen'));
const AccountSettingsScreen = lazy(() => import('containers/pages/settings/AccountSettingsScreen'));
const TFAConfirmOldMethodScreen = lazy(() => import('containers/pages/settings/TFAConfirmOldMethodScreen'));
const TFACodeConfirmationScreen = lazy(() => import('containers/pages/settings/TFALinkYourPhoneNumberScreen/tfaCodeConfirmation'));
const TFABindGoogleAuthenticatorScreen = lazy(() => import('containers/pages/settings/TFABindGoogleAuthenticatorScreen'));
const TFASafeRecoveryKeyScreen = lazy(() => import('containers/pages/settings/TFASafeRecoveryKeyScreen'));
const TFAGoogleAuthenticatorSetupCodeInputScreen = lazy(
    () => import('containers/pages/settings/TFAGoogleAuthenticatorSetupCodeInputScreen')
);
const TFALinkYourPhoneNumberScreen = lazy(() => import('containers/pages/settings/TFALinkYourPhoneNumberScreen'));
const AccountDeletionCancellationScreen = lazy(() => import('containers/pages/settings/AccountDeletionCancellationScreen'));

export const SETTINGS_ROUTES = [
    '/settings/personal-data',
    '/settings/account-settings',
    '/settings/account-settings/confirm-2fa/:newMethod',
    '/settings/account-settings/bind-google-authenticator',
    '/settings/account-settings/google-authentication-code',
    '/settings/account-settings/safe-recovery-key',
    '/settings/account-settings/link-your-phone-number',
    '/settings/account-settings/sms-authentication-code',
    '/settings/account-settings/cancel-deletion'
];

const SettingsRouter: React.FC = () => {
    return (
        <SettingsProvider>
            <Routes>
                <Route element={<AuthRoute />}>
                    <Route path="/personal-data" element={<PersonalDataScreen />} />
                    <Route path="/account-settings" element={<AccountSettingsScreen />} />
                    <Route path="/account-settings/confirm-2fa/:newMethod" element={<TFAConfirmOldMethodScreen />} />
                    <Route path="/account-settings/link-your-phone-number" element={<TFALinkYourPhoneNumberScreen />} />
                    <Route path="/account-settings/bind-google-authenticator" element={<TFABindGoogleAuthenticatorScreen />} />
                    <Route path="/account-settings/safe-recovery-key" element={<TFASafeRecoveryKeyScreen />} />
                    <Route path="/account-settings/google-authentication-code" element={<TFAGoogleAuthenticatorSetupCodeInputScreen />} />
                    <Route path="/account-settings/sms-authentication-code" element={<TFACodeConfirmationScreen />} />
                    <Route path="/account-settings/cancel-deletion" element={<AccountDeletionCancellationScreen />} />
                </Route>
                <Route path="*" element={<NotFoundScreen />} />
            </Routes>
        </SettingsProvider>
    );
};

export default SettingsRouter;
