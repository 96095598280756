export { default as ApariArrowBig } from './apari-arrow-big.png';
export { default as ApariArrowSmall } from './apari-arrow-small.svg';
export { default as ApariLogoPurple } from './apari-logo-purple.svg';
export { default as ApariLogoWhite } from './apari-logo-white.svg';
export { default as LaptopAndMobile } from './laptop-and-mobile.png';
export { default as LaptopAndMobileAgent } from './laptop-and-mobile-agent.png';
export { default as MTDIcon } from './mtd-icon.svg';
export { default as Pause } from './pause.png';
export { default as Play } from './play.png';
export { default as PurpleBackgroundRight } from './purple-background-right.png';
export { default as SA100Icon } from './sa100-icon.svg';
export { default as EPLogo } from './logo-evelyn-partners.svg';
export { default as EPCondensedLogo } from './ep-condensed-logo.svg';
export { default as EPWhiteGoldLogo } from './ep-logo-white-gold.svg';
export { default as EPGradientBg } from './ep-gradient-background.webp';
export { default as EpLaptopAndMobile } from './ep-laptop-and-mobile.png';
