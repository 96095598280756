import { makeStyles } from 'tss-react/mui';
import { COLORS } from '@apari-shared/constants';

export default makeStyles({ name: 'ApSlider' })(() => ({
    sliderContainer: {
        '& .MuiSlider-root.Mui-disabled': {
            opacity: 0.5
        }
    },
    slider: {
        '& .MuiSlider-rail': {
            height: 7,
            color: COLORS.apariBlack10,
            borderRadius: 13
        },
        '& .MuiSlider-track': {
            height: 7,
            color: COLORS.primary,
            borderRadius: 13
        },
        '& .MuiSlider-thumb': {
            width: 21,
            height: 21,
            color: COLORS.primary,
            marginTop: '-7.5px'
        }
    },
    input: {
        width: 70,
        textAlign: 'center'
    }
}));
