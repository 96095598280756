import { createTheme } from '@mui/material/styles';
import { generateTheme } from '@apari-shared/utils';
import { GlobalServices } from 'utils';

let themeOptions = generateTheme(GlobalServices.getClient());
themeOptions = {
    ...themeOptions
};

const theme = createTheme(themeOptions);

export default theme;
