import React from 'react';
import { Slider } from '@mui/material';
import { ApariIntegerField } from 'components';
import { FormikContextType } from 'formik';
import { GlobalServices } from 'utils';
import globalStyles from 'styles/globalStyles';
import clsx from 'clsx';
import styles from './styles';
interface Props {
    control: string;
    formik: FormikContextType<any>;
    disabled?: boolean;
    title: string;
    min?: number;
    max?: number;
}

const ApSlider: React.FC<Props> = ({ control, formik, disabled, title, min = 0, max = 100 }) => {
    const { classes: globalClasses } = globalStyles();
    const { classes } = styles();
    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        formik.setFieldValue(control, newValue);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = event.target;
        if (GlobalServices.isDefined(Number(value))) {
            formik.setFieldValue(control, Number(value) > max ? max : Number(value));
        }
    };

    const handleBlur = () => {
        if (formik.values.split < 0) {
            formik.setFieldValue(control, 0);
        }

        if (formik.values.split > max) {
            formik.setFieldValue(control, max);
        }
    };
    return (
        <div className={clsx(globalClasses.marginTop20)}>
            <div className={clsx(globalClasses.font10weight400Dark)}>{title}</div>
            <div className={clsx(globalClasses.flexRowCenter, classes.sliderContainer)}>
                <Slider
                    className={clsx(globalClasses.marginRight24, classes.slider)}
                    value={formik.values[control]}
                    onChange={handleSliderChange}
                    step={1}
                    disabled={disabled}
                    max={max}
                    min={min}
                />
                <ApariIntegerField
                    name={control}
                    className={classes.input}
                    value={formik.values[control]}
                    margin="dense"
                    formatType="target"
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                    disabled={disabled}
                    inputProps={{
                        step: 1,
                        min: 0,
                        max: 100,
                        style: {
                            textAlign: 'center'
                        }
                    }}
                />
                <div className={clsx(globalClasses.font13weight400Dark, globalClasses.marginLeft8, globalClasses.marginTop4)}>%</div>
            </div>
        </div>
    );
};

export default ApSlider;
