import React from 'react';
import clsx from 'clsx';
import { FormikContextType } from 'formik';
import PhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styles from './styles';

type ApPhoneNumberFieldProps = Omit<PhoneInputProps, 'specialLabel' | 'containerClass'> & {
    control?: string;
    formik?: FormikContextType<any>;
    label: string;
    className?: string;
};

const ApPhoneNumberField: React.FC<ApPhoneNumberFieldProps> = ({
    formik,
    control,
    onChange,
    value,
    label,
    disabled,
    className,
    ...rest
}) => {
    const { classes } = styles();

    const handleChange = (value: string) => {
        formik!.setFieldValue(control!, value);
    };

    return (
        <PhoneInput
            country="gb"
            disabled={disabled}
            specialLabel={label}
            onChange={onChange ?? handleChange}
            containerClass={clsx(classes.phoneInputContainerOverride, disabled && classes.phoneInputContainerDisabled, className)}
            value={formik ? formik!.values[control!] ?? '' : value ?? ''}
            {...rest}
        />
    );
};

export default ApPhoneNumberField;
