import { COLORS } from '@apari-shared/constants';
import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'MessageRejectedBody' })(() => ({
    violationPolicyBtn: {
        padding: 0,
        textDecoration: 'underline',
        fontSize: 16,
        fontWeight: 700,
        color: COLORS.gray6,
        lineHeight: 1.2
    }
}));
