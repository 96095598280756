import { GlobalServices } from 'utils';
import { CLIENT } from '@apari-shared/constants';

const BASE_FONTS = {
    PRIMARY_FONT: 'Telegraf',
    SECONDARY_FONT: 'Monument Extended',
    TERTIARY_FONT: 'Apotek Cond',
    QUATERNARY_FONT: 'Roboto'
};

const getFontForTheme = () => {
    const client = GlobalServices.getClient();
    switch (client) {
        case CLIENT.EP:
            return {
                PRIMARY_FONT: 'Raleway, sans-serif',
                SECONDARY_FONT: 'Raleway, sans-serif',
                TERTIARY_FONT: 'Raleway, sans-serif',
                QUATERNARY_FONT: 'Raleway, sans-serif'
            };
        default:
            return BASE_FONTS;
    }
};

const FONTS = getFontForTheme();

export default FONTS;
