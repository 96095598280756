import { GLOBAL } from 'constants/index';
import { GlobalServices } from 'utils';

export default function useHMRCFraudHeaders(): Record<string, any> {
    const { screen, window } = getScreenDetails();
    const fraudHeader = {
        browserPlugins: getPlugins(),
        deviceId: GlobalServices.getUUID(),
        multiFactor: get2FADetails(),
        screens: screen,
        //eslint-disable-next-line
        // @ts-ignore
        timezone: new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1], //eslint-disable-line
        version: GLOBAL.VERSION,
        windowSize: window
    };

    return {
        fraudHeader
    };
}

function getPlugins() {
    let pluginString = '';
    const { plugins } = navigator;
    const { length } = plugins;

    for (let i = 0; i < length; i++) {
        pluginString += plugins[i].name;
        if (i < length - 1) {
            pluginString += ',';
        }
    }

    return encodeURI(pluginString);
}

function get2FADetails() {
    const timestamp = localStorage.getItem(GLOBAL.MFA_TIMESTAMP);
    const reference = localStorage.getItem(GLOBAL.MFA_REFERENCE) as string;
    const type = localStorage.getItem(GLOBAL.MFA_TYPE);

    if (timestamp && reference && type) {
        return encodeURI(`type=${type}&timestamp=${timestamp}&unique-reference=${reference}`);
    } else {
        return null;
    }
}

function getScreenDetails() {
    const { devicePixelRatio, screen } = window;
    const { height, width, colorDepth, availHeight, availWidth } = screen;

    return {
        screen: `width=${width}&height=${height}&scaling-factor=${devicePixelRatio}&colour-depth=${colorDepth}`,
        window: `width=${availWidth}&height=${availHeight}`
    };
}
