import React from 'react';
import { Localisation } from 'utils';
import './styles.css';
import { useStyles } from './styles';

interface Props {
    onChange?: (value: boolean) => void;
    className?: string;
    switchClassName?: string;
    trueLabel?: string;
    falseLabel?: string;
    value: boolean;
    disabled?: boolean;
    dataCy?: string;
    inDialog?: boolean;
}

const ApariTextSwitch: React.FC<Props> = ({
    className = '',
    trueLabel,
    falseLabel,
    disabled = false,
    value,
    onChange,
    dataCy,
    switchClassName,
    inDialog
}) => {
    const { classes, cx } = useStyles({ inDialog });

    const handleChange = () => {
        !disabled && onChange && onChange(!value);
    };

    return (
        <div className={cx(classes.container, className)} data-cy={dataCy}>
            <label className={classes.switch}>
                <input type="checkbox" checked={value} onChange={handleChange} disabled={disabled} className={classes.hiddenInput} />
                <div
                    className={cx(classes.slider, {
                        [classes.sliderChecked]: value,
                        [classes.switchDisabled]: disabled,
                        ...(switchClassName && { [switchClassName]: value })
                    })}
                >
                    <span className={classes.onSwitch}>{trueLabel || Localisation.localize('general.YES')}</span>
                    <span className={classes.offSwitch}>{falseLabel || Localisation.localize('general.NO')}</span>
                </div>
            </label>
        </div>
    );
};

export default ApariTextSwitch;
