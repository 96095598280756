import React from 'react';
import clsx from 'clsx';
import { ListItem } from '@mui/material';
import globalStyles from 'styles/globalStyles';
import { ApariCheckbox, ApariRadio } from 'components';
import styles from './styles';

type Props = {
    onChange: (checked: boolean) => void;
    checked: boolean;
    disabled?: boolean;
    text: string;
    additionalText?: string;
    singleSelect?: boolean;
    name?: string;
};

const ApariSelectField: React.FC<Props> = ({ onChange, checked, text, singleSelect, disabled = false, additionalText }) => {
    const { classes } = styles();
    const { classes: globalClasses } = globalStyles();

    const handleCheck = () => {
        onChange(!checked);
    };

    return (
        <ListItem
            button
            disabled={disabled}
            onClick={handleCheck}
            className={clsx(classes.card, globalClasses.flexRowStart, checked && classes.cardChecked)}
        >
            <div style={{ display: 'inline-block', zIndex: 0 }}>
                {singleSelect ? (
                    <ApariRadio onChange={handleCheck} disabled={disabled} checked={checked} />
                ) : (
                    <ApariCheckbox onChange={handleCheck} disabled={disabled} checked={checked || false} value={checked || false} />
                )}
            </div>
            <div
                className={clsx(
                    globalClasses.flexColumnStart,
                    globalClasses.marginLeft8,
                    globalClasses.marginRightAuto,
                    globalClasses.inputsFont,
                    globalClasses.font13weight600,
                    classes.text
                )}
            >
                {text}
                {additionalText && <div className={clsx(globalClasses.font13weight400Dark)}>{additionalText}</div>}
            </div>
        </ListItem>
    );
};

export default ApariSelectField;
