import { makeStyles } from 'tss-react/mui';
import { COLORS } from '@apari-shared/constants';

export default makeStyles({ name: 'ApariCheckboxWithLabel' })(theme => ({
    control: {
        borderColor: theme.palette.primary.main,
        paddingLeft: theme.spacing(3),
        width: '100%'
    },
    labelMargin: {
        marginLeft: 12,
        lineHeight: '14px'
    },
    cardChecked: {
        background: theme.palette.primary.main
    },
    checkboxIcon: {
        width: 32,
        height: 32,
        borderRadius: 4,
        border: 2,
        borderStyle: 'solid',
        borderColor: theme.palette.primary.main
    },
    checkboxCheckedIcon: {
        width: 32,
        height: 32,
        borderRadius: 4,
        border: 2,
        borderStyle: 'solid',
        fill: 'none',
        borderColor: theme.palette.primary.main,
        background: theme.palette.primary.main
    },
    apari80: {
        color: COLORS.apariBlack80
    }
}));
