import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { SettingsProvider } from 'context/SettingsContext';
import NotFoundScreen from 'containers/pages/user/NotFoundScreen';

const LogoutSessionOnPasswordChangeOnAnotherBrowserScreen = lazy(
    () => import('containers/pages/authentication/LogoutSessionOnPasswordChangeOnAnotherBrowserScreen')
);
const AgentRedirectionScreen = lazy(() => import('containers/pages/authentication/AgentRedirectionScreen'));

export const REDIRECTION_ROUTES = ['/agent', '/logout-session'];
const RedirectRouter: React.FC = () => {
    return (
        <SettingsProvider>
            <Routes>
                <Route path="/agent" element={<AgentRedirectionScreen />} />
                <Route path="/logout-session" element={<LogoutSessionOnPasswordChangeOnAnotherBrowserScreen />} />
                <Route path="*" element={<NotFoundScreen />} />
            </Routes>
        </SettingsProvider>
    );
};

export default RedirectRouter;
