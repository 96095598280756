import React, { lazy, useContext } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AuthenticationContext } from 'context/AuthenticationContext';
import { PublicRoute } from 'routers';

const AccountConfirmationScreen = lazy(() => import('containers/pages/authentication/AccountConfirmationScreen'));
const SignInScreen = lazy(() => import('containers/pages/authentication/SignInScreen'));
const QualificationScreen = lazy(() => import('containers/pages/authentication/QualificationScreen'));
const SignUpWithEmailScreen = lazy(() => import('containers/pages/authentication/SignUpWithEmail'));
const ForgotPasswordScreen = lazy(() => import('containers/pages/authentication/ForgotPasswordScreen'));
const NewPasswordScreen = lazy(() => import('containers/pages/authentication/NewPasswordScreen'));
const SetUpPasswordScreen = lazy(() => import('containers/pages/authentication/SetUpPasswordScreen'));
const ResendEmailVerificationScreen = lazy(() => import('containers/pages/authentication/ResendEmailVerificationScreen'));
const TFACodeInputScreen = lazy(() => import('containers/pages/authentication/TFACodeInputScreen'));
const TFAUseRecoveryKeyScreen = lazy(() => import('containers/pages/authentication/TFAUseRecoveryKeyScreen'));
import NotFoundScreen from 'containers/pages/user/NotFoundScreen';

export const PUBLIC_ROUTES = [
    '/sign-in',
    '/sign-up-with-email',
    '/resend-email-verification',
    '/confirm-account',
    '/authentication-code',
    '/use-recovery-key',
    '/forgot-password',
    '/new-password',
    '/setup-password',
    '/qualification'
];

const IndexRedirection: React.FC = () => {
    const { authenticated, redirectionPrefix } = useContext(AuthenticationContext);

    if (!authenticated) return <Navigate to="/sign-in" replace />;
    window.location.href = redirectionPrefix + '/dashboard';
    return <div />;
};

const PublicRoutes: React.FC = () => {
    return (
        <Routes>
            <Route index element={<IndexRedirection />} />
            <Route path="/sign-in" element={<SignInScreen />} />
            <Route path="/authentication-code" element={<TFACodeInputScreen />} />
            <Route path="/use-recovery-key" element={<TFAUseRecoveryKeyScreen />} />
            <Route element={<PublicRoute />}>
                <Route path="/resend-email-verification" element={<ResendEmailVerificationScreen />} />
                <Route path="/confirm-account" element={<AccountConfirmationScreen />} />
                <Route path="/qualification" element={<QualificationScreen />} />
                <Route path="/sign-up-with-email" element={<SignUpWithEmailScreen />} />
                <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
                <Route path="/new-password" element={<NewPasswordScreen />} />
                <Route path="/setup-password" element={<SetUpPasswordScreen />} />
            </Route>
            <Route path="*" element={<NotFoundScreen />} />
        </Routes>
    );
};

export default PublicRoutes;
