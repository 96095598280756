import React, { Suspense, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import { AnimatePresence } from 'framer-motion';
import { FullScreenLoading } from 'components/index';
import useQuery from 'hooks/useQuery';
import { QUERY_PARAMS } from 'types';
import { GlobalServices, LocalStorageServices } from 'utils';
import PublicRouter from './PublicRouter';
import SettingsRouter from './SettingsRouter';
import TwoFactorRouter from './TwoFactor';
import RedirectRouter from './RedirectRouter';

const AppRouter: React.FC = () => {
    const { trackPageView } = useMatomo();
    const location = useLocation();

    const { queryValues } = useQuery(location, [
        QUERY_PARAMS.UTM_SOURCE,
        QUERY_PARAMS.UTM_MEDIUM,
        QUERY_PARAMS.UTM_CAMPAIGN,
        QUERY_PARAMS.UTM_TERM,
        QUERY_PARAMS.UTM_CONTENT,
        QUERY_PARAMS.GCLID,
        QUERY_PARAMS.FCLID,
        QUERY_PARAMS.REFERRAL_CODE
    ]);

    useEffect(() => {
        if (!GlobalServices.isEmpty(queryValues)) {
            LocalStorageServices.setJSON('QUERY_PARAMETERS', queryValues);
        }
    }, []);

    React.useEffect(() => {
        trackPageView({
            documentTitle: location.pathname
        });
    }, [location]);

    return (
        // React 18 requires to define children prop, this is external library so we can't add it
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <AnimatePresence>
            <Suspense fallback={<FullScreenLoading />}>
                <Routes>
                    <Route path="/*" element={<PublicRouter />} />
                    <Route path="/settings/*" element={<SettingsRouter />} />
                    <Route path="/two-factor/*" element={<TwoFactorRouter />} />
                    <Route path="/redirect/*" element={<RedirectRouter />} />
                </Routes>
            </Suspense>
        </AnimatePresence>
    );
};

export default AppRouter;
