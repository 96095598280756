import React, { useState, createContext, useEffect, PropsWithChildren } from 'react';
import { User, TaxEntity, UserTwoFactorAuthMethodEnum, PersonalData, UserApi, TaxEntityControllerApi, PersonalApi } from '@apari/core-api';
import {
    GrantHmrcAuthorityControllerApi,
    HmrcConnectionStatusControllerApi,
    HMRCConnectionStatusForUserDtoHmrcUserConnectionStatusEnum,
    UkTaxIdentificationPropertyDto
} from '@apari/uk-mtd-api';
import { HMRCConnectionStatusForUserDto } from '@apari/uk-mtd-api/models';

const hmrcConnectionStatusControllerApi = new HmrcConnectionStatusControllerApi();

interface SettingsContextInterface {
    user: User;
    personalData: PersonalData;
    taxEntity: TaxEntity;
    hmrcAuthorization: UkTaxIdentificationPropertyDto | undefined;
    setUser: (user: User) => void;
    setPersonalData: (personalData: PersonalData) => void;
    setTaxEntity: (taxEntity: TaxEntity) => void;
    setHMRCAuthorization: (hmrcAuthorization: UkTaxIdentificationPropertyDto | undefined) => void;
    updateTFAMethod: (TFAMethod: UserTwoFactorAuthMethodEnum | undefined) => void;
    apiFailed: boolean;
    loading: boolean;
    refreshUserData: (skipLoading?: boolean) => void;
    refreshPersonalData: () => void;
    getSettingsData: () => void;
    hmrcStatus?: HMRCConnectionStatusForUserDtoHmrcUserConnectionStatusEnum;
}

export const SettingsContext = createContext({} as SettingsContextInterface);

const userAPI = new UserApi();
const teAPI = new TaxEntityControllerApi();
const personalApi = new PersonalApi();
const grantAuthority = new GrantHmrcAuthorityControllerApi();

export const SettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [apiFailed, setApiFailed] = useState(false);
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState<User>({} as User);
    const [personalData, setPersonalData] = useState<PersonalData>({} as PersonalData);
    const [taxEntity, setTaxEntity] = useState<TaxEntity>({} as TaxEntity);
    const [hmrcAuthorization, setHMRCAuthorization] = useState<UkTaxIdentificationPropertyDto | undefined>();
    const [hmrcStatus, setHmrcStatus] = useState<HMRCConnectionStatusForUserDtoHmrcUserConnectionStatusEnum>();

    useEffect(() => {
        getSettingsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSettingsData = () => {
        try {
            setLoading(true);
            Promise.allSettled([
                userAPI.getCurrentUser(),
                teAPI.getTaxEntityOwnedByUser(),
                personalApi.getPersonalData1(),
                hmrcConnectionStatusControllerApi.getStatusForUser(),
                grantAuthority.checkGrantAuthorityStatus()
            ])
                .then(results => {
                    handlePromisesResult(results);
                    setLoading(false);
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    setApiFailed(true);
                });
        } catch (e) {
            setLoading(false);
            setApiFailed(true);
        }
    };

    const updateTFAMethod = (TFAMethod: UserTwoFactorAuthMethodEnum | undefined) => {
        setUser({ ...user, ...{ twoFactorAuthMethod: TFAMethod } });
    };

    const refreshUserData = (skipLoading?: boolean) => {
        !skipLoading && setLoading(true);
        userAPI
            .getCurrentUser()
            .then(res => {
                const data = res.data;
                setUser(data);
            })
            .catch(err => {
                console.log(err);
                setApiFailed(true);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const refreshPersonalData = async () => {
        setLoading(true);
        try {
            const response = await personalApi.getPersonalData1();
            setPersonalData(response.data);
        } catch (e) {
            console.log(e);
            setApiFailed(true);
        }
        setLoading(false);
    };

    const handlePromisesResult = (results: any[]) => {
        results.forEach((item, index) => {
            if (item.status === 'fulfilled') {
                if (index === 0) {
                    const temp = item.value.data as User;
                    setUser(temp);
                } else if (index === 1) {
                    const temp = item.value.data as TaxEntity;
                    setTaxEntity(temp);
                } else if (index === 2) {
                    const temp = item.value.data as PersonalData;
                    setPersonalData(temp);
                } else if (index === 3) {
                    const temp = item.value.data as HMRCConnectionStatusForUserDto;
                    setHmrcStatus(temp.hmrcUserConnectionStatus);
                } else {
                    const temp = item.value.data as UkTaxIdentificationPropertyDto;
                    if (temp?.grantAuthorityTime) {
                        setHMRCAuthorization(temp);
                    } else {
                        setHMRCAuthorization(undefined);
                    }
                }
            } else {
                if (index === 4) {
                    setHMRCAuthorization(undefined);
                }
            }
        });
    };

    return (
        <SettingsContext.Provider
            value={{
                apiFailed,
                loading,
                user,
                personalData,
                taxEntity,
                hmrcAuthorization,
                hmrcStatus,
                setUser,
                setPersonalData,
                setTaxEntity,
                setHMRCAuthorization,
                updateTFAMethod,
                refreshUserData,
                refreshPersonalData,
                getSettingsData
            }}
        >
            {children}
        </SettingsContext.Provider>
    );
};
