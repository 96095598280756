import { GlobalServices } from 'utils';

const DECIMAL = '.';
const GROUP = ',';
const NUMBER_PRECISION = 2;

class NumberServices {
    static format(value: number | string, integer = null): number | string {
        if (GlobalServices.isDefined(value)) {
            const tmpValue = this.toFixedNoRounding(Number(value));
            const strValue = tmpValue.toString();
            let intValue;
            let decimalValue;

            if (strValue.indexOf('.') !== -1) {
                intValue = strValue.split('.')[0];
                decimalValue = strValue.split('.')[1];
            } else {
                intValue = strValue;
                decimalValue = '00';
            }
            const intValueGroup = intValue.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1' + GROUP);
            if (integer) {
                return intValueGroup;
            } else {
                return intValueGroup + DECIMAL + decimalValue;
            }
        }
        return value;
    }

    static round(value: number): number {
        return Math.round((value + Number.EPSILON) * 100) / 100;
    }

    static toFixedNoRounding(num: number, p = NUMBER_PRECISION): string | number {
        let n = Number.isNaN(num) || !isFinite(num) ? 0 : num;
        n = Math.round((n + Number.EPSILON) * 10000) / 10000;
        n = Number.isNaN(n) ? num : n;
        const reg = new RegExp('^-?\\d+(?:\\.\\d{0,' + p + '})?', 'g');
        const a = n.toString().match(reg)![0];
        const dot = a.indexOf('.');
        if (dot === -1) {
            // integer, insert decimal dot and pad up zeros
            return a + '.' + '0'.repeat(p);
        }
        const b = p - (a.length - dot) + 1;
        return b > 0 ? a + '0'.repeat(b) : a;
    }
}

export default NumberServices;
