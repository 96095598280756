import React, { PropsWithChildren, useContext, useState, useEffect } from 'react';
import { NavigateFunction } from 'react-router';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { isEmpty } from 'lodash';
import Cookies from 'universal-cookie/lib/Cookies';
import { PaymentStatusDtoSubscriptionTypeEnum } from '@apari/core-api/models/payment-status-dto';
import {
    AuthenticationApi,
    CurrentUserDto,
    GoogleAuthenticatorActivationData,
    ImpersonationControllerApi,
    JwtRequest,
    LogoutControllerApi,
    OauthApi,
    OAuthRequestProviderEnum,
    PaymentControllerApi,
    PaymentStatusDto,
    PaymentStatusDtoStatusEnum,
    SignUpData,
    SubscriptionBooking,
    SubscriptionControllerApi,
    TwoFactorAuthData,
    TwoFactorAuthDataTwoFactorAuthMethodEnum,
    TwoFactorAuthenticationApi,
    UserApi
} from '@apari/core-api';
import { MoneyHubUserConnectionControllerApi } from '@apari/banking-api';
import { AuthenticationUtils, JWT, RedirectionPrefix, RoleNames } from '@apari-shared/utils';
import { COMMONS, GLOBAL } from 'constants/index';
import { EncryptionServices, GlobalServices, Localisation, LocalStorageServices, NetworkService, SubmissionServices } from 'utils';
import { NotificationTypes, SubscriptionTypeValues, TOUR_TYPES, ToursStatus } from 'types';
import { AppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';

const twoFactorAuthenticationApi = new TwoFactorAuthenticationApi();
const authenticationApi = new AuthenticationApi();
const oauth2Api = new OauthApi();
const userApi = new UserApi();
const subscriptionControllerApi = new SubscriptionControllerApi();
const paymentControllerApi = new PaymentControllerApi();
const moneyHubUserConnectionControllerApi = new MoneyHubUserConnectionControllerApi();
const logoutControllerApi = new LogoutControllerApi();
const impersonationController = new ImpersonationControllerApi();

interface AuthenticationContextInterface {
    authenticated: boolean;
    user: CurrentUserDto;
    signUpEmail: string;
    signUpDialogOpen: boolean;
    emailForbiddenDialogOpen: boolean;
    confirmationDialogOpen: boolean;
    verificationEmailSent: boolean;
    bookedSubscriptionPackages?: SubscriptionBooking;
    paymentStatus?: PaymentStatusDto;
    setPaymentStatus: React.Dispatch<React.SetStateAction<PaymentStatusDto | undefined>>;
    googleTwoFactorAuthenticationData: GoogleAuthenticatorActivationData;
    setUser: React.Dispatch<React.SetStateAction<CurrentUserDto>>;
    setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
    setSignUpDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setEmailForbiddenDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setConfirmationDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setVerificationEmailSent: React.Dispatch<React.SetStateAction<boolean>>;
    bookingDataLoading: boolean;
    finishingWizardLoading: boolean;
    toursStatus?: ToursStatus;
    setToursStatus: React.Dispatch<React.SetStateAction<ToursStatus | undefined>>;
    setBookingDataLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setGoogleTwoFactorAuthenticationData: React.Dispatch<React.SetStateAction<GoogleAuthenticatorActivationData>>;
    setCurrentUser: (ignoreTour?: boolean, ignoreLoading?: boolean) => Promise<void>;
    setMtdWizardDone: () => void;
    setAuthToken: (token: string | undefined) => void;
    signUp: (user: SignUpData) => void;
    signOut: (sessionExpired?: boolean) => void;
    signIn: (user: JwtRequest) => void;
    handleOAuthLogin: (provider: OAuthRequestProviderEnum, code: string, state: string, navigate: NavigateFunction) => void;
    confirmTwoFactorAuthentication: (code: string, redirectUrl: string, callbackFunction?: () => void) => void;
    resendTwoFactorCode: () => void;
    resendEmailVerificationToken: (email: string) => void;
    fetchBookedSubscriptionData: () => void;
    finishTour: (tourType: TOUR_TYPES) => void;
    startTour: (tourType: TOUR_TYPES) => void;
    doesUserHaveLowestRequiredPackage: (requiredPackage: PaymentStatusDtoSubscriptionTypeEnum, onlyActive?: boolean) => boolean;
    isImpersonating: boolean;
    setSmsTwoFactorAuthenticationData: React.Dispatch<React.SetStateAction<Partial<{ telephoneNumber: string; token: string }>>>;
    smsTwoFactorAuthenticationData: Partial<{ telephoneNumber: string; token: string }>;
    renderAuthPage: number;
    userLoading: boolean;
    redirectionPrefix?: RedirectionPrefix;
    onboardingDone: boolean;
    guidedTourAnchorEl: null | HTMLElement;
    setGuidedTourAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>;
    handleEndImpersonation: (redirectTo?: string) => Promise<void>;
}

export const AuthenticationContext = React.createContext({} as AuthenticationContextInterface);

export const AuthenticationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [user, setUser] = useState<CurrentUserDto>({});
    const [userLoading, setUserLoading] = useState(false);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const [signUpEmail, setSignUpEmail] = useState<string>('');
    const [signUpDialogOpen, setSignUpDialogOpen] = useState<boolean>(false);
    const [emailForbiddenDialogOpen, setEmailForbiddenDialogOpen] = useState<boolean>(false);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState<boolean>(false);
    const [verificationEmailSent, setVerificationEmailSent] = useState<boolean>(false);
    const [googleTwoFactorAuthenticationData, setGoogleTwoFactorAuthenticationData] = useState<Partial<GoogleAuthenticatorActivationData>>(
        {}
    );
    const [smsTwoFactorAuthenticationData, setSmsTwoFactorAuthenticationData] = useState<
        Partial<{ telephoneNumber: string; token: string }>
    >({});
    const [bookingDataLoading, setBookingDataLoading] = useState(true);
    const [bookedSubscriptionPackages, setBookedSubscriptionPackages] = useState<SubscriptionBooking>();
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatusDto>();
    const [finishingWizardLoading, setFinishingWizardLoading] = useState(false);
    const [toursStatus, setToursStatus] = useState<ToursStatus>();
    const [isImpersonating, setIsImpersonating] = useState(false);
    const [renderAuthPage, setRenderAuthPage] = useState(0);
    const [redirectionPrefix, setRedirectionPrefix] = useState<RedirectionPrefix>();
    const [guidedTourAnchorEl, setGuidedTourAnchorEl] = useState<null | HTMLElement>(null);

    const { pushInstruction, trackEvent } = useMatomo();

    const { showLoadingBar, hideLoadingBar, showNotifications, setCurrentTaxYear } = useContext(AppContext);

    const navigate = useNavigate();

    useEffect(() => {
        async function fetchCurrentTaxYear() {
            if (authenticated) {
                const currentTaxYear = await SubmissionServices.getCurrentTaxYear();
                setCurrentTaxYear(currentTaxYear);
            } else {
                setCurrentTaxYear(undefined);
            }
        }

        fetchCurrentTaxYear();
    }, [authenticated]);

    const removeAuthToken = () => {
        const cookie = new Cookies();
        cookie.remove(GLOBAL.JWT_COOKIE_KEY, { path: '/', domain: NetworkService.getCookieDomain() });
    };

    const setCurrentUser = async (ignoreTour?: boolean, ignoreLoading?: boolean) => {
        !ignoreLoading && setUserLoading(true);
        try {
            const response = await userApi.getCurrentUserInfo();
            pushInstruction('setUserId', response.data.id);
            setUser(response.data);
            if (!ignoreTour) {
                setToursStatus({
                    [TOUR_TYPES.MENU]: isOnboardingDone(),
                    [TOUR_TYPES.MENU_MOBILE_FULL]: isOnboardingDone(),
                    [TOUR_TYPES.MENU_MOBILE]: isOnboardingDone()
                });
            }
        } catch (e) {
            console.log('e', e);
        }
        !ignoreLoading && setUserLoading(false);
    };

    const setAuthToken = async (token: string | undefined = '') => {
        setUserLoading(true);
        const decodedToken: JWT = jwt_decode(token);
        setIsImpersonating(!isEmpty(decodedToken.impersonatorId));
        const cookie = new Cookies();
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);
        cookie.set(GLOBAL.JWT_COOKIE_KEY, token, {
            path: '/',
            expires: expireDate,
            domain: NetworkService.getCookieDomain()
        });
        setRedirectionPrefix(AuthenticationUtils.getRedirectionPrefix(decodedToken));
        setAuthenticated(true);
    };

    const finishTour = (tourType: TOUR_TYPES) => {
        setToursStatus(prevState => ({ ...prevState, [tourType]: true }));
    };

    const startTour = (tourType: TOUR_TYPES) => {
        setToursStatus(prevState => ({ ...prevState, [tourType]: false }));
    };

    const setMtdWizardDone = async () => {
        setFinishingWizardLoading(true);
        await userApi.setMTDWizardDone();
        await setCurrentUser(true, true);
        setFinishingWizardLoading(false);
    };

    const signUp = async (user: SignUpData) => {
        showLoadingBar();
        try {
            let queryParameters = LocalStorageServices.getJSON('QUERY_PARAMETERS');
            if (!GlobalServices.isEmpty(queryParameters) && queryParameters) {
                queryParameters = GlobalServices.keysToCamelCase(queryParameters);
            }
            await authenticationApi.register({ ...user, ...(queryParameters && { ...queryParameters }) });
            trackEvent({
                category: 'form-event',
                name: 'SignUpWithEmailForm',
                action: 'successfully-submitted'
            });
            LocalStorageServices.removeItem('QUERY_PARAMETERS');
            setSignUpEmail(user.email);
            setConfirmationDialogOpen(false);
            setSignUpDialogOpen(true);
        } catch (e) {
            hideLoadingBar();
            trackEvent({
                category: 'form-event',
                name: 'SignUpWithEmailForm',
                action: 'submit-error'
            });
            if (e.responseCode === 403) {
                setConfirmationDialogOpen(false);
                setEmailForbiddenDialogOpen(true);
            } else {
                showNotifications({ type: NotificationTypes.ERROR, message: e });
            }
        }
        hideLoadingBar();
    };

    const signOut = async (sessionExpired = false) => {
        if (!sessionExpired) {
            try {
                showLoadingBar();
                await logoutControllerApi.logout();
                hideLoadingBar();
            } catch (e) {
                console.log('e', e);
                hideLoadingBar();
            }
        }
        await removeAuthToken();
        setAuthenticated(false);
        setUser({});
        setIsImpersonating(false);
        setPaymentStatus(undefined);
        setBookedSubscriptionPackages(undefined);
        setToursStatus(undefined);
        LocalStorageServices.removeItem('twoFactorAuthenticationData');
        LocalStorageServices.removeItem('selectedChartBusinessTab');
        LocalStorageServices.removeItem(GLOBAL.MFA_TYPE);
        LocalStorageServices.removeItem(GLOBAL.MFA_TIMESTAMP);
        LocalStorageServices.removeItem(GLOBAL.MFA_REFERENCE);
        LocalStorageServices.removeItem(GLOBAL.LICENSE_KEY);
        setGoogleTwoFactorAuthenticationData({});
        setSmsTwoFactorAuthenticationData({});
    };

    const signIn = async (user: JwtRequest) => {
        showLoadingBar();
        try {
            const response = await authenticationApi.login1(user);
            const decodedToken: Record<string, any> = jwt_decode(response.data.token!);
            await setAuthToken(response.data.token);
            localStorage.setItem(COMMONS.LOCAL_STORAGE_KEYS.afterLogin, 'true');
            localStorage.setItem(COMMONS.LOCAL_STORAGE_KEYS.initialLogin, String(response.data.initialLogin || 'false'));
            if (decodedToken.role === RoleNames.AGENT) {
                window.location.replace(response.data.applicationUrl + '/account-setup/welcome');
            } else {
                moneyHubUserConnectionControllerApi.triggerManualSyncForAllUserConnections();
                const redirectUrl = new URL(response.data.applicationUrl!);
                window.location.replace(location.protocol + '//' + location.host + redirectUrl.pathname + '/dashboard');
            }

            LocalStorageServices.removeItem('twoFactorAuthenticationData');
        } catch (e) {
            if (e.twoFactorRequired) {
                LocalStorageServices.setJSON('twoFactorAuthenticationData', e.data);
            } else {
                await removeAuthToken();
                showNotifications({
                    type: NotificationTypes.ERROR,
                    message: typeof e === 'string' ? e : Localisation.localize('serverErrors.SIGN_IN_ERROR')
                });
            }
        }
        hideLoadingBar();
    };

    const handleOAuthLogin = async (provider: OAuthRequestProviderEnum, code: string, state: string, navigate: any) => {
        try {
            const response = await oauth2Api.login({ provider, code, state });
            await setAuthToken(response.data.token);
            localStorage.setItem(COMMONS.LOCAL_STORAGE_KEYS.initialLogin, String(response.data.initialLogin || 'false'));
            if (response.data.initialLogin) {
                window.location.href = redirectionPrefix + '/onboarding';
            } else {
                window.location.href = redirectionPrefix + '/dashboard';
            }
        } catch (e) {
            if (e.twoFactorRequired) {
                LocalStorageServices.setJSON('twoFactorAuthenticationData', e.data);
            } else if (e.registeredUsingEmail) {
                navigate('/sign-in', { replace: true });
                showNotifications({
                    type: NotificationTypes.ERROR,
                    message: e.message
                });
            } else {
                navigate('/sign-in', { replace: true });
                showNotifications({
                    type: NotificationTypes.ERROR,
                    message: Localisation.localize('serverErrors.singleSignInError')
                });
            }
        }
    };

    const confirmTwoFactorAuthentication = async (code: string, redirectUrl: string, callbackFunction?: () => void) => {
        showLoadingBar();
        try {
            const twoFactorAuthenticationData = LocalStorageServices.getJSON('twoFactorAuthenticationData');
            await setTFAInformation(code, twoFactorAuthenticationData as TwoFactorAuthData);
            const response = await twoFactorAuthenticationApi.verifyCode(code, twoFactorAuthenticationData as TwoFactorAuthData);
            await setAuthToken(response.data.token);
            window.location.href = response.data.applicationUrl!;
            LocalStorageServices.removeItem('twoFactorAuthenticationData');
        } catch (e) {
            if (e.responseCode === 411) {
                console.log('e', e);
                showNotifications({ type: NotificationTypes.ERROR, message: Localisation.localize('serverErrors.SOMETHING_WENT_WRONG') });
            } else if (e.tokenErrorType === 1) {
                showNotifications({ type: NotificationTypes.ERROR, message: Localisation.localize('serverErrors.TFA_CODE_EXPIRED') });
                callbackFunction && callbackFunction();
            } else if (e.tokenErrorType === 0) {
                showNotifications({ type: NotificationTypes.ERROR, message: Localisation.localize('serverErrors.TFA_CODE_INVALID') });
            } else if (typeof e === 'string') {
                showNotifications({ type: NotificationTypes.ERROR, message: e });
            } else {
                showNotifications({ type: NotificationTypes.ERROR, message: Localisation.localize('serverErrors.SOMETHING_WENT_WRONG') });
            }
        }
        hideLoadingBar();
    };

    const setTFAInformation = async (code: string, tfaAuthData: TwoFactorAuthData) => {
        if (tfaAuthData) {
            localStorage.setItem(
                GLOBAL.MFA_TYPE,
                tfaAuthData.twoFactorAuthMethod === TwoFactorAuthDataTwoFactorAuthMethodEnum.EMAIL ? 'AUTH_CODE' : 'TOTP'
            );
        }

        localStorage.setItem(GLOBAL.MFA_TIMESTAMP, moment().utc().format('YYYY-MM-DDTHH:mm').concat('Z'));
        localStorage.setItem(GLOBAL.MFA_REFERENCE, EncryptionServices.encrypt(code, GLOBAL.AES_KEY));
    };

    const resendTwoFactorCode = async () => {
        showLoadingBar();
        try {
            const twoFactorAuthenticationData: TwoFactorAuthData = LocalStorageServices.getJSON(
                'twoFactorAuthenticationData'
            ) as TwoFactorAuthData;
            const response = await twoFactorAuthenticationApi.resendCode(twoFactorAuthenticationData);
            LocalStorageServices.setJSON('twoFactorAuthenticationData', response.data as unknown as Record<string, unknown>);
            if (response.data.twoFactorAuthMethod === TwoFactorAuthDataTwoFactorAuthMethodEnum.EMAIL) {
                showNotifications({
                    type: NotificationTypes.SUCCESS,
                    message: Localisation.localize('TFA_CODE_INPUT_SCREEN.CODE_IS_SUCCESSFULLY_SENT_EMAIL')
                });
            } else if (response.data.twoFactorAuthMethod === TwoFactorAuthDataTwoFactorAuthMethodEnum.SMS) {
                if (response.data.blockDurationInSeconds === 0) {
                    showNotifications({
                        type: NotificationTypes.SUCCESS,
                        message: Localisation.localize('TFA_CODE_INPUT_SCREEN.CODE_IS_SUCCESSFULLY_SENT_SMS')
                    });
                } else {
                    setRenderAuthPage(prevState => prevState + 1);
                }
            }
        } catch (e) {
            showNotifications({ type: NotificationTypes.ERROR, message: Localisation.localize('serverErrors.errorResendCode') });
        }
        hideLoadingBar();
    };

    const resendEmailVerificationToken = async (email: string) => {
        showLoadingBar();
        try {
            await authenticationApi.resendConfirmationLink(email);
            showNotifications({
                type: NotificationTypes.SUCCESS,
                message: Localisation.localize('ResendEmailVerificationScreen.emailSuccessfullyResent')
            });
            setVerificationEmailSent(true);
        } catch (e) {
            setVerificationEmailSent(false);
            if (e.accountAlreadyConfirmed) {
                showNotifications({ type: NotificationTypes.ERROR, message: e.message });
            } else {
                showNotifications({ type: NotificationTypes.ERROR, message: e });
            }
        }
        hideLoadingBar();
    };

    const fetchBookedSubscriptionData = async () => {
        setBookingDataLoading(true);
        await getActiveSubscriptionData();
        await getStatusOfPaymentData();
        setBookingDataLoading(false);
    };

    const getActiveSubscriptionData = async () => {
        try {
            const activeSubscriptionResponse = await subscriptionControllerApi.getActiveSubscription();
            setBookedSubscriptionPackages(activeSubscriptionResponse.data);
            if (activeSubscriptionResponse.data.stripeSubscriptionId) {
                localStorage.setItem(
                    GLOBAL.LICENSE_KEY,
                    EncryptionServices.encrypt(activeSubscriptionResponse.data.stripeSubscriptionId, GLOBAL.AES_KEY)
                );
            }
        } catch (e) {
            localStorage.removeItem(GLOBAL.LICENSE_KEY);
            setBookedSubscriptionPackages(undefined);
            console.log(e);
        }
    };

    const getStatusOfPaymentData = async () => {
        try {
            const paymentStatusResponse = await paymentControllerApi.getStatusOfPayment();
            setPaymentStatus(paymentStatusResponse.data);
        } catch (e) {
            setPaymentStatus(undefined);
            console.log(e);
        }
    };

    const doesUserHaveLowestRequiredPackage = (requiredPackage: PaymentStatusDtoSubscriptionTypeEnum, onlyActive?: boolean) => {
        if (paymentStatus) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const requiredValue: number = SubscriptionTypeValues[requiredPackage];
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const activeValue: number = SubscriptionTypeValues[paymentStatus.subscriptionType!] as unknown as number;
            if (activeValue >= requiredValue) {
                if (onlyActive) {
                    return paymentStatus.status === PaymentStatusDtoStatusEnum.ACTIVE;
                } else {
                    return (
                        paymentStatus.status === PaymentStatusDtoStatusEnum.ACTIVE ||
                        paymentStatus.status === PaymentStatusDtoStatusEnum.TRIALING
                    );
                }
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    //ToDo: just set to true as part of the bugfix, better investigation needed if the method and the places can be removed completely, strange behaviour when I did so so left it like this
    const isOnboardingDone = () => {
        return true;
    };

    const handleEndImpersonation = async (redirect?: string) => {
        const cookie = new Cookies();
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 30);

        try {
            showLoadingBar();
            const response = await impersonationController.endImpersonation(user.id!);
            if (response.data) {
                cookie.set(GLOBAL.JWT_COOKIE_KEY, response.data.token, {
                    path: '/',
                    expires: expireDate,
                    domain: NetworkService.getCookieDomain()
                });
                localStorage.removeItem('agentHOSIDComplete');
                navigate(redirect ?? '/redirect/agent', { replace: true });
            }
            hideLoadingBar();
        } catch (e) {
            hideLoadingBar();
            console.log('e', e);
            showNotifications({ type: NotificationTypes.ERROR, message: Localisation.localize('serverErrors.SOMETHING_WENT_WRONG') });
        }
    };

    return (
        <AuthenticationContext.Provider
            value={{
                user,
                userLoading,
                authenticated,
                signUpEmail,
                signUpDialogOpen,
                emailForbiddenDialogOpen,
                confirmationDialogOpen,
                verificationEmailSent,
                googleTwoFactorAuthenticationData,
                setUser,
                setAuthenticated,
                setSignUpDialogOpen,
                setEmailForbiddenDialogOpen,
                setConfirmationDialogOpen,
                setVerificationEmailSent,
                setGoogleTwoFactorAuthenticationData,
                bookedSubscriptionPackages,
                paymentStatus,
                setPaymentStatus,
                setCurrentUser,
                setAuthToken,
                setMtdWizardDone,
                toursStatus,
                setToursStatus,
                finishingWizardLoading,
                signUp,
                signOut,
                signIn,
                handleOAuthLogin,
                confirmTwoFactorAuthentication,
                resendTwoFactorCode,
                resendEmailVerificationToken,
                fetchBookedSubscriptionData,
                bookingDataLoading,
                setBookingDataLoading,
                finishTour,
                startTour,
                doesUserHaveLowestRequiredPackage,
                isImpersonating,
                setSmsTwoFactorAuthenticationData,
                smsTwoFactorAuthenticationData,
                renderAuthPage,
                redirectionPrefix,
                onboardingDone: isOnboardingDone(),
                guidedTourAnchorEl,
                setGuidedTourAnchorEl,
                handleEndImpersonation
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};
