import React from 'react';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextFieldProps } from '@mui/material';
import { DATE_FORMAT } from 'constants/index';
import { ApariTextField } from 'components';
import { GlobalServices } from 'utils';

type ApDatePickerFieldProps = Partial<Omit<DatePickerProps<any, any>, 'helperText'>> & {
    helperText?: any;
    format?: string;
    name?: string;
    error?: boolean;
    placeholder?: string;
    fullWidth?: boolean;
    onChange: (date: any, keyboardInputValue?: string | undefined) => void;
};

const BasicTextComponent = (props: TextFieldProps) => {
    const { disabled, error, helperText, ...rest } = props;

    return (
        <ApariTextField
            error={error}
            helperText={helperText}
            fullWidth={false}
            noShrinkClass
            disabled={disabled}
            onChange={e => {
                e.preventDefault();
            }}
            {...rest}
            color="primary"
        />
    );
};

export const ApDatePickerField: React.FC<ApDatePickerFieldProps> = ({
    value,
    format,
    error,
    name,
    helperText,
    placeholder,
    fullWidth,
    ...rest
}) => {
    const getDateFormat = () => {
        return GlobalServices.isEmpty(format) ? DATE_FORMAT.UK_STANDARD : format;
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
                inputFormat={getDateFormat()}
                value={value}
                openTo="year"
                views={['year', 'month', 'day']}
                renderInput={(props: any) => (
                    <BasicTextComponent
                        {...props}
                        name={name}
                        error={error}
                        helperText={helperText}
                        placeholder={placeholder}
                        fullWidth={fullWidth}
                        margin="dense"
                    />
                )}
                {...rest}
            />
        </LocalizationProvider>
    );
};

export default ApDatePickerField;
