import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';
import Cookies from 'universal-cookie/lib/Cookies';
import jwt_decode from 'jwt-decode';
import { GLOBAL } from 'constants/index';
import theme from 'styles/theme';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { CLIENT } from '@apari-shared/constants';
import { GlobalServices } from 'utils';

const CURRENT_CLIENT = GlobalServices.getClient();
const cookie = new Cookies();
const jwt_cookie = cookie.get(GLOBAL.JWT_COOKIE_KEY);

let decoded = '';

if (jwt_cookie) {
    decoded = jwt_decode(jwt_cookie);
}

const getSideIdForClient = () => {
    switch (CURRENT_CLIENT) {
        case CLIENT.EP:
            return 11;
        default:
            return GLOBAL.REACT_APP_MATOMO_SITEID;
    }
};

const instance = createInstance({
    urlBase: GLOBAL.REACT_APP_MATOMO_URL,
    siteId: CURRENT_CLIENT !== CLIENT.APARI && GLOBAL.NODE_ENV === 'production' ? getSideIdForClient() : GLOBAL.REACT_APP_MATOMO_SITEID,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    userId: decoded !== '' ? decoded.id : 'Anonymous' // optional, default value: `undefined`
});

const ApariTheme = React.lazy(() => import('./styles/theme/APARI'));
const EpTheme = React.lazy(() => import('./styles/theme/EP'));

const ThemeSelector: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    return (
        <>
            <React.Suspense fallback={<></>}>
                {CURRENT_CLIENT === CLIENT.EP && <EpTheme />}
                {CURRENT_CLIENT === CLIENT.APARI && <ApariTheme />}
            </React.Suspense>
            {children}
        </>
    );
};

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <BrowserRouter>
        {/*React 18 requires to define children prop, this is external library so we can't add it*/}
        {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
        {/*@ts-ignore*/}
        <MatomoProvider value={instance}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <ThemeSelector>
                        <CssBaseline />
                        <App />
                    </ThemeSelector>
                </ThemeProvider>
            </StyledEngineProvider>
        </MatomoProvider>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
