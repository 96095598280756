import React from 'react';
import { CheckboxProps, SvgIcon } from '@mui/material';

const UncheckedIcon: React.FC<CheckboxProps> = () => {
    return (
        <SvgIcon>
            <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="22.3333" height="23" rx="2.5" stroke="#EAEEF2" />
            </svg>
        </SvgIcon>
    );
};

export default UncheckedIcon;
