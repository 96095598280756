import React from 'react';
import { CheckboxProps, SvgIcon } from '@mui/material';

interface CheckedIconProps extends CheckboxProps {
    iconColor?: string;
}

const CheckedIcon: React.FC<CheckedIconProps> = ({ disabled, iconColor }) => {
    return (
        <SvgIcon>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="22.3333" height="23" rx="2.5" stroke="#EAEEF2" />
                <path
                    d="M5.83203 13.3333L9.16536 16.6666L17.4987 8.33325"
                    stroke={disabled ? '#EAEEF2' : iconColor ?? '#673FFA'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};

export default CheckedIcon;
