// eslint-disable-next-line @typescript-eslint/no-namespace
declare namespace EmptyTypes {
    export type String = '';
    export type Object = Record<string, never>;
    export type Array = never[];
}

export type Empty = EmptyTypes.Array | EmptyTypes.Object | EmptyTypes.String;

export type DetermineEmptyValueType<T> = T extends string
    ? EmptyTypes.String
    : T extends unknown[]
    ? EmptyTypes.Array
    : T extends Record<string, unknown>
    ? EmptyTypes.Object
    : never;

export enum PasswordStrengthTypes {
    STRONG = 'strong',
    MEDIUM = 'medium',
    WEAK = 'weak'
}
