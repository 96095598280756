export enum MESSAGE_TYPES {
    HMRCAUTH = 'HMRC_AUTH',
    OBLIGATIONS_SYNCED = 'OBLIGATIONS_SYNCED',
    CALCULATION_TRIGGERED = 'CALCULATION_TRIGGERED',
    CALCULATION_DATA_RETRIEVAL_DONE = 'CALCULATION_DATA_RETRIEVAL_DONE',
    CALCULATION_RETRIEVAL_DONE = 'CALCULATION_RETRIEVAL_DONE',
    QUARTER_SUBMISSION_ERROR = 'QUARTER_SUBMISSION_ERROR',
    QUARTER_SUBMISSION_SUCCESS_DONE = 'QUARTER_SUBMISSION_SUCCESS_DONE',
    HMRC_REQUEST_ERROR = 'HMRC_REQUEST_ERROR',
    PERIODIC_UPDATES_SYNC_SUCCESS = 'PERIODIC_UPDATES_SYNC_SUCCESS',
    PERIODIC_UPDATES_SYNC_ERROR = 'PERIODIC_UPDATES_SYNC_ERROR',
    CALCULATION_TRIGGERING_ERROR = 'CALCULATION_TRIGGERING_ERROR',
    CALCULATION_DATA_RETRIEVAL_ERROR = 'CALCULATION_DATA_RETRIEVAL_ERROR',
    TAX_YEAR_DISABLED = 'TAX_YEAR_DISABLED',
    HMRC_BUSINESS_SYNC_SUCCESS = 'HMRC_BUSINESS_SYNC_SUCCESS',
    HMRC_BUSINESS_SYNC_ERROR = 'HMRC_BUSINESS_SYNC_ERROR',
    BISS_SYNC_SUCCESS = 'BISS_SYNC_SUCCESS',
    BISS_SYNC_ERROR = 'BISS_SYNC_ERROR',
    EOP_SUBMISSION_DONE = 'EOP_SUBMISSION_DONE',
    EOP_SUBMISSION_ERROR = 'EOP_SUBMISSION_ERROR',
    CRYSTALLISATION_DONE = 'CRYSTALLISATION_DONE',
    ANNUAL_SUMMARY_SYNC_SUCCESS = 'ANNUAL_SUMMARY_SYNC_SUCCESS',
    ANNUAL_SUMMARY_SYNC_ERROR = 'ANNUAL_SUMMARY_SYNC_ERROR',
    ANNUAL_SUMMARY_SUBMISSION_ERROR = 'ANNUAL_SUMMARY_SUBMISSION_ERROR',
    ANNUAL_SUMMARY_SUBMISSION_SUCCESS = 'ANNUAL_SUMMARY_SUBMISSION_SUCCESS'
}

export type PayloadInterface = {
    error: string | null;
    errorCode: string | null;
    errorDescription: string | null;
    message: string | null;
    submissionId?: string;
};

export type MessageInterface = {
    userId: string;
    webSocketMessageType: MESSAGE_TYPES;
    payload: PayloadInterface | any;
};
