import { makeStyles } from 'tss-react/mui';
import { FONTS } from 'constants/index';
import { COLORS } from '@apari-shared/constants';

export default makeStyles({ name: 'ApPhoneNumberField' })(theme => ({
    phoneInputContainerOverride: {
        '& .country-list': {
            border: `1px ${theme.palette.primary.main} solid`,
            boxShadow: 'none',
            borderRadius: 0,
            '& li': {
                borderBottom: '1px solid #F9F9F9',
                padding: '6px 16px 6px 46px !important'
            },
            '& span': {
                fontSize: 13,
                fontWeight: 400,
                fontFamily: FONTS.PRIMARY_FONT,
                color: COLORS.apariBlack80 + ' !important'
            },
            '& .flag': {
                transform: 'scale(1)',
                margin: 0,
                top: 4
            }
        },
        '& .react-tel-input': {
            fontFamily: FONTS.PRIMARY_FONT
        },
        '&& .special-label': {
            fontFamily: FONTS.PRIMARY_FONT,
            color: COLORS.apariBlack80,
            fontSize: 10,
            left: 90
        },
        '& .form-control': {
            padding: '9px 14px !important',
            color: COLORS.apariBlack80 + ' !important',
            borderRadius: '2px !important',
            marginLeft: '82px !important',
            width: 'calc(100% - 82px) !important',
            fontSize: '13px !important',
            '&:hover': {
                borderColor: theme.palette.primary.main
            },
            '&:focus': {
                borderColor: theme.palette.primary.main,
                boxShadow: `0 0 0 1px ${theme.palette.primary.main}`
            }
        },
        '& .flag-dropdown': {
            width: 75,
            borderRadius: '2px !important',
            backgroundColor: COLORS.apariWhite,
            '&:hover': {
                backgroundColor: '#F9F9F9'
            },
            border: `1px solid #CACACA`,
            '&.open': {
                border: `1px ${theme.palette.primary.main} solid`,
                backgroundColor: '#F9F9F9',
                '& .selected-flag': {
                    backgroundColor: '#F9F9F9'
                }
            },
            '& .flag': {
                transform: 'scale(1.15)'
            },
            '& .selected-flag': {
                width: 'unset',
                padding: 8,
                '&:hover': {
                    backgroundColor: '#F9F9F9'
                }
            },
            '& .arrow': {
                borderLeft: '0px !important',
                borderTop: '0px !important',
                borderRight: `1.3px solid ${COLORS.apariBlack20} !important`,
                borderBottom: `1.3px solid ${COLORS.apariBlack20} !important`,
                transform: 'rotate(45deg)',
                height: '7px !important',
                width: '7px !important',
                left: '39px !important',
                top: '8px !important',
                marginTop: '0px !important'
            },
            '& .up': {
                top: '10px !important',
                transform: 'rotate(225deg)'
            }
        }
    },
    phoneInputContainerDisabled: {
        '& .form-control': {
            cursor: 'default',
            color: COLORS.apariBlack20 + ' !important',
            '&::placeholder': {
                color: COLORS.apariBlack10 + ' !important'
            },
            '&:hover': {
                color: COLORS.apariBlack20 + ' !important',
                borderColor: COLORS.apariBlack20
            },
            '&:focus': {
                color: COLORS.apariBlack20 + ' !important',
                borderColor: COLORS.apariBlack20,
                boxShadow: `0 0 0 1px ${COLORS.apariBlack20}`
            }
        },
        '& .special-label': {
            color: COLORS.apariBlack20
        },
        '& .flag-dropdown': {
            backgroundColor: COLORS.apariWhite,
            '&:hover': {
                backgroundColor: COLORS.apariWhite
            }
        },
        '& .flag': {
            opacity: 0.6
        }
    }
}));
