import { NotificationTypes } from './index';

export type OpenNotificationsPayload = {
    type: NotificationTypes;
    message: string;
    onClose?: () => void;
};

export type RouterProps = {
    authenticated: boolean;
    acceptedTermsAndConditions: boolean;
    wizardDone: boolean;
};

export enum SubscriptionTypeValues {
    STARTER = 0,
    STANDARD = 1,
    PREMIUM = 2,
    YEARLY = 3
}

export enum QUERY_PARAMS {
    UTM_SOURCE = 'utm_source',
    UTM_MEDIUM = 'utm_medium',
    UTM_CAMPAIGN = 'utm_campaign',
    UTM_TERM = 'utm_term',
    UTM_CONTENT = 'utm_content',
    GCLID = 'gclid',
    FCLID = 'fclid',
    REFERRAL_CODE = 'referral_code'
}

export type FloatingMenuItems = {
    name?: string;
    action: () => void;
    icon?: React.ReactElement;
};
