import React from 'react';
import { IconButton, useMediaQuery, useTheme } from '@mui/material';
import clsx from 'clsx';
import { ArrowBackIcon } from 'resources/icons';
import globalStyles from 'styles/globalStyles';
import styles from './styles';

type Props = {
    messages?: string[];
    customMessages?: JSX.Element[];
    handleBack?: () => void;
    title?: string;
    noSidePadding?: boolean;
    classNameMessages?: string;
    className?: string;
    iconClassName?: string;
    noBottomPadding?: boolean;
    alternativeFont?: boolean;
    noBackButton?: boolean;
    signUpScreen?: boolean;
    noTextColor?: boolean;
};

const ScreenTitle: React.FC<Props> = ({
    title,
    messages,
    customMessages,
    noSidePadding,
    handleBack,
    classNameMessages,
    className,
    iconClassName,
    noBottomPadding,
    alternativeFont,
    noBackButton,
    signUpScreen,
    noTextColor
}) => {
    const { classes } = styles({ alternativeFont, signUpScreen, noTextColor });
    const { classes: globalClasses } = globalStyles();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    const renderBackButton = () => {
        if (handleBack) {
            return (
                <IconButton size="small" onClick={handleBack} className={clsx([globalClasses.marginRight8, iconClassName])}>
                    <ArrowBackIcon />
                </IconButton>
            );
        }
    };

    return (
        <div className={clsx(classes.subHeader, globalClasses.flexColumn, className)}>
            <div
                className={clsx(
                    classes.messageWrapper,
                    noSidePadding && globalClasses.noHorizontalPadding,
                    !noBottomPadding && classes.messageWrapperBottomPadding
                )}
            >
                {(title || handleBack) && (
                    <div className={clsx(classes.titleWrapper, globalClasses.flexRow)}>
                        {renderBackButton()}
                        {title && (!isMobile || !alternativeFont) && (
                            <div className={clsx(classes.titleText, globalClasses.flexColumnCenter)}>{title}</div>
                        )}
                    </div>
                )}
                {noBackButton && title && isMobile && (
                    <div className={clsx(classes.titleWrapper, globalClasses.flexRow)}>
                        <div className={clsx(classes.titleText, globalClasses.flexColumn)}>{title}</div>
                    </div>
                )}
                {messages && <div className={clsx(classes.message, classNameMessages)}>{messages[0]}</div>}
                {customMessages && <div className={clsx(classes.message)}>{customMessages[0]}</div>}
            </div>
        </div>
    );
};

export default ScreenTitle;
