import { makeStyles } from 'tss-react/mui';
import { COLORS } from '@apari-shared/constants';

export default makeStyles({ name: 'ApAmountField' })(theme => ({
    root: {
        '& .MuiInputAdornment-root p': {
            color: COLORS.apariBlack40
        },
        '& .Mui-disabled .MuiInputAdornment-root p': {
            color: COLORS.apariBlack20
        },
        '& MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid'
        },
        '& .Mui-disabled.MuiInput-underline.Mui-disabled:before': {
            borderBottomStyle: 'solid',
            borderColor: COLORS.apariBlack20
        },
        '& .MuiTypography-root': {
            fontSize: '16px !important'
        },
        '& .MuiInput-input': {
            paddingBottom: 1,
            fontSize: 16,
            [theme.breakpoints.down('md')]: {
                fontSize: 13
            }
        }
    },
    boldedRoot: {
        '& .MuiInputAdornment-root p': {
            fontSize: '15px !important'
        },
        '& .MuiInputBase-input': {
            fontSize: '15px !important'
        }
    },
    filledRoot: {
        '& .MuiInputAdornment-root p': {
            color: COLORS.apariBlack80
        }
    },
    filledBoldedRoot: {
        '& .MuiInputBase-root:before': {
            borderColor: COLORS.apariBlack80
        }
    }
}));
