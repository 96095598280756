import i18n from './I18n/I18n';

export function localize(key: string, variable?: Record<string, unknown> | undefined): string {
    return i18n.t(key, variable);
}

export function changeLanguage(newLanguage: string): void {
    i18n.changeLanguage(newLanguage, err => {
        if (err) return console.log('something went wrong loading', err);
    });
}

export function getCurrentLanguage(): string {
    return i18n.language;
}
