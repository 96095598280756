import React from 'react';
import clsx from 'clsx';
import { FormControlLabel, ListItemText } from '@mui/material';
import globalStyles from 'styles/globalStyles';
import { ApariCheckbox } from 'components';
import styles from './styles';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';

type Props = {
    name?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
    checked?: boolean;
    disabled?: boolean;
    primaryBorderColor?: boolean;
    invertedColors?: boolean;
    darkBackground?: boolean;
    text: string;
    round?: boolean;
    className?: string;
    labelClassName?: string;
    iconColor?: string;
};

const ApariCheckboxWithLabel: React.FC<Props> = props => {
    const { text, darkBackground, round, className, disabled, labelClassName, iconColor, ...rest } = props;
    const { classes } = styles();
    const { classes: globalClasses } = globalStyles();
    return (
        <div className={clsx(globalClasses.flexRowStart, classes.control, className)}>
            <FormControlLabel
                disabled={disabled}
                control={
                    <ApariCheckbox
                        {...(round && {
                            icon: <CircleUnchecked className={clsx(classes.apari80)} />,
                            checkedIcon: <CircleCheckedFilled className={clsx(classes.apari80)} color="secondary" />,
                            disableRipple: true
                        })}
                        disabled={disabled}
                        iconColor={iconColor}
                        {...rest}
                    />
                }
                label={
                    <ListItemText
                        disableTypography
                        className={clsx(
                            globalClasses.flexColumnCenter,
                            classes.labelMargin,
                            darkBackground ? globalClasses.font12weight600Dark : globalClasses.font12weight600Primary,
                            labelClassName
                        )}
                        primary={text}
                    />
                }
            />
        </div>
    );
};

export default ApariCheckboxWithLabel;
