import React from 'react';
import clsx from 'clsx';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import { InputAdornment, TextFieldProps } from '@mui/material';
import { ApariTextField } from 'components';
import { FormikContextType, getIn } from 'formik';
import { GlobalServices } from 'utils';
import styles from './styles';

type AmountInputProps = Omit<TextFieldProps, 'name'> & {
    name: string;
    valueType?: 'number' | 'string' | 'formatted-string';
    bolded?: boolean;
    allowNegative?: boolean;
    formik: FormikContextType<any>;
};

type Target = {
    target: {
        value?: any;
    };
};

type AmountFormatProps = NumberFormatProps & {
    inputRef: (instance: typeof NumberFormat | null) => void;
    onChange: (target: Target) => void;
    name: string;
};

const AmountFormat = React.forwardRef<HTMLElement, AmountFormatProps>(function AmountFormat(props, ref) {
    const { onChange, ...other } = props;
    return (
        <NumberFormat
            allowNegative={false}
            {...other}
            getInputRef={ref}
            decimalScale={2}
            fixedDecimalScale={true}
            thousandSeparator=","
            isNumericString
            onValueChange={values => {
                onChange({
                    target: {
                        value: values
                    }
                });
            }}
        />
    );
});

const MTDFormAmountInputField: React.FC<AmountInputProps> = ({
    formik,
    name,
    valueType = 'number',
    variant = 'standard',
    margin = 'none',
    bolded,
    allowNegative,
    ...rest
}) => {
    const { classes } = styles();

    return (
        <ApariTextField
            classes={{
                root: clsx(
                    classes.root,
                    bolded && classes.boldedRoot,
                    !GlobalServices.isEmpty(getIn(formik.values, name)) && classes.filledRoot,
                    !GlobalServices.isEmpty(getIn(formik.values, name)) && bolded && classes.filledBoldedRoot
                )
            }}
            inputProps={{ inputMode: 'decimal', allowNegative: allowNegative ?? false }}
            InputProps={{
                inputComponent: AmountFormat as any, // eslint-disable-line @typescript-eslint/no-explicit-any
                startAdornment: <InputAdornment position="start">£</InputAdornment>
            }}
            fullWidth
            name={name}
            variant={variant}
            margin={margin}
            value={getIn(formik.values, name) ?? ''}
            placeholder={getIn(formik.values, name) ? '' : '0.00'}
            error={getIn(formik.touched, name) && Boolean(getIn(formik.errors, name))}
            helperText={getIn(formik.touched, name) && getIn(formik.errors, name)}
            onChange={(e: React.ChangeEvent<any>) => {
                const { value } = e.target;
                switch (valueType) {
                    case 'number':
                        formik.setFieldValue(name, value.floatValue || value.floatValue === 0 ? value.floatValue : '');
                        break;
                    case 'string':
                        formik.setFieldValue(name, value.value || '');
                        break;
                    case 'formatted-string':
                        formik.setFieldValue(name, value.formattedValue || '');
                        break;
                    default:
                        formik.setFieldValue(name, value.floatValue || '');
                        break;
                }
            }}
            {...rest}
        />
    );
};

export default MTDFormAmountInputField;
