import { makeStyles } from 'tss-react/mui';
import { useContext } from 'react';
import { AppContext } from 'context/AppContext';
import { COLORS } from '@apari-shared/constants';

type Props = {
    confirm2faScreen?: boolean;
};

export default makeStyles<Props>({ name: 'ApariOTPInput' })((_theme, { confirm2faScreen }) => {
    const whitelabelledStyles = useContext(AppContext).whitelabelledApp.styles.otpInput;

    return {
        input: {
            borderBottomWidth: 2,
            border: 'none',
            width: '23px !important',
            marginRight: 4,
            borderBottom: confirm2faScreen ? '2px solid ' + COLORS.apariBlack20 : whitelabelledStyles.borderBottom,
            background: whitelabelledStyles.background,
            color: confirm2faScreen ? COLORS.apariBlack80 : whitelabelledStyles.color,
            fontWeight: whitelabelledStyles.fontWeight
        },
        inputWrapper: {
            '& input:focus': {
                outline: 0
            }
        }
    };
});
