import { makeStyles } from 'tss-react/mui';

export default makeStyles({ name: 'ApariCheckbox' })(() => ({
    root: {
        padding: 0,
        '& svg': {
            fontSize: 23
        }
    }
}));
