import React, { useState } from 'react';
import { IconButton, InputAdornment, SvgIconPropsColorOverrides, TextFieldProps } from '@mui/material';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { OverridableStringUnion } from '@mui/types';
import { ApariTextField } from '../index';
import clsx from 'clsx';
import styles from './styles';

type Props = Omit<TextFieldProps, 'helperText'> & {
    darkBackground?: boolean;
    noShrinkClass?: boolean;
    Icon?: React.ElementType;
    onIconPress?: () => void;
    iconStyle?: string;
    alternativeFont?: boolean;
    helperText?: any;
    iconColor?:
        | OverridableStringUnion<
              'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
              SvgIconPropsColorOverrides
          >
        | undefined;
};

const ApariPasswordField: React.FC<Props> = props => {
    const { className, InputLabelProps, InputProps, Icon, onIconPress, iconStyle, disabled, alternativeFont, ...rest } = props;
    const { classes } = styles();
    const [showPassword, setShowPassword] = useState(false);
    const handleShowPassword = () => setShowPassword(prevState => !prevState);
    const handleShowPasswordMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <ApariTextField
            className={className}
            fullWidth
            disabled={disabled}
            inputProps={{
                passwordrules: 'minlength: 20; required: lower; required: upper; required: digit; required: [-];'
            }}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                className: clsx(alternativeFont && classes.alternativeFont),
                ...InputProps,
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            disabled={disabled}
                            onClick={onIconPress ? onIconPress : handleShowPassword}
                            onMouseDown={handleShowPasswordMouseDown}
                            className={clsx(classes.showPasswordIcon)}
                            size="large"
                        >
                            {Icon ? (
                                <Icon className={iconStyle} />
                            ) : showPassword ? (
                                <VisibilityOutlined {...(props.iconColor && { color: props.iconColor })} />
                            ) : (
                                <VisibilityOffOutlined {...(props.iconColor && { color: props.iconColor })} />
                            )}
                        </IconButton>
                    </InputAdornment>
                )
            }}
            InputLabelProps={InputLabelProps}
            {...rest}
        />
    );
};

export default ApariPasswordField;
